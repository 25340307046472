<template>
    <div id="content" class="fade-in">

        <div id="topNavBar">
          <h3 id="appTitle">App Solidário</h3>
        </div>

        <div id="MeusChatsContent" >
          <div id="nonChatsMessage" v-if="meusChats.length == 0">
            <h1>
              Você não possui novas mensagens
              <i class="bi bi-chat-fill"></i>
            </h1>
          </div>

          <div  v-for="(chat, index) in meusChats" :key="index">
            <button id="chat" class="btn btn-secondary" @click="AbrirChat(chat[0])">

                  <span id="assunto">
                    <i id="chatIcon" class="bi bi-chat-fill"></i> 
                      {{ chat[1] }}
                  </span>

                  <h4>{{ chat[3] }}</h4>

                  <img id="foto_de_perfil" :src="$imagesDoServidor + chat[2]" alt="">
            </button>
            <!-- <div v-show="chat[1] !== ''" class="btn-group">
                <button id="chat" class="btn btn-success" @click="AbrirChat(chat[0])">
                  <span id="assunto">
                    <i id="chatIcon" class="bi bi-chat-fill"></i> 
                      "{{ chat[1] }}"
                  </span>

                  <img id="foto_de_perfil" :src="$imagesDoServidor + chat[2]" alt="">
                </button>
            </div> -->
          </div>
        </div>


        <div id="newNavBar">
          <router-link to="/home" class="nav-link"><i class="bi bi-house-fill navicon"></i></router-link>
          <router-link to="/meuschats" class="nav-link"><i class="bi bi-chat-fill navicon"></i></router-link>
          <router-link to="/postar" class="nav-link"><i class="bi bi-plus-square-fill navicon"></i></router-link>
          <router-link to="/perfil" class="nav-link"><i class="bi bi-person-fill navicon"></i></router-link>
          <router-link to="/sobre" class="nav-link"><i class="bi bi-info-square-fill navicon"></i></router-link>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
import utils from '../utils';

export default{
    name: "meuschats",

    data: function(){
        return{
            meusChats: [],
            iduser: ""
        }
    },

    methods: {
        Sair(){
          console.log("Cache limpo");
          localStorage.clear();
          this.$router.push("/");
        },

        DescobrirId(){
			    const dataToSend = {
				    "opcode": 13,
				    "email": localStorage.getItem("session-email")
			    }

          axios.post(this.$backendDoServidor, dataToSend)
            .then(response =>{
              // console.log(response.data);
              this.iduser = response.data;
            })
            .catch(err =>{
              console.error("Erro ao decobrir id: ", err);
            })
		    },

        CarregarMeusChats(){
            console.log("Carregando chats...");
            // console.log(this.iduser);

            const dataToSend2 = {
                "opcode": 14,
                "email": localStorage.getItem("session-email")
            }

            axios.post(this.$backendDoServidor, dataToSend2)
                .then(response =>{
                    // console.log("Meus chats carregados!");
                    console.log(response.data[0][0][0]);
                    this.meusChats = [];
                    this.meusChats = response.data;
                    // for (let i = 0; i < response.data.length; i++){
                          //     this.meusChats.push(response.data[i])
                    // }
                })
                .catch(err =>{
                    console.error("Erro ao carregar meus chats: ", err);
                })

              // setTimeout(this.CarregarMeusChats, 500);
        },

        AbrirChat(value){
            console.log("Abrindo chat...");
            this.$router.push({name: "chatinstanciado", params: {instancia: value}});
        }
    },

    mounted: function(){
        utils.IsLogged();
        this.DescobrirId();
        this.CarregarMeusChats();
    },

    computed:{      
    }
}

</script>

<style scoped>
*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.fade-in {
  opacity: 0; /* Inicialmente, oculta o conteúdo */
  animation: fadeInAnimation 0.5s ease-in forwards; /* Animação de fade-in */
}
@keyframes fadeInAnimation {
  from {
    opacity: 0; /* Começa completamente transparente */
  }
  to {
    opacity: 1; /* Torna-se totalmente visível */
  }
}

#foto_de_perfil{
  width: 100px;
  height: 100px;
  margin-left: 10px;
  border-radius: 10%;
  object-fit: cover; /* Garante que a imagem cubra todo o espaço designado */
  display: block; /* Permite a centralização vertical */
}

#topNavBar{
  top: 0;
  z-index: 2;
  position: fixed;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-between; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  width: 100%;
  padding: 2.5px;
  /* background: rgb(8, 96, 211); */
  /* background-color: var(--azul-1); */
  background: rgb(24,62,159);
  /* background: linear-gradient(0deg, rgba(24,62,159,1) 0%, rgba(27,156,228,1) 100%);  */
  overflow: hidden;
  border: none;
  box-shadow: 0px 0px 5px;
}
#appTitle{
  color: white;
  text-shadow: 1px 1px 10px black;
  margin-left: 1.2rem;
}

#nonChatsMessage{
  text-align: center;
  margin-top: 10rem;
}

#content{
  /* height: 100vh;
  width: 100vw; */
  background-color: rgb(228, 228, 228);
  /* background-color: black; */
  padding-bottom: 5vh;
}

#newNavBar{
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-around; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  /* background: rgb(167,167,167); */
  background: rgb(225, 225, 225);
  /* background: linear-gradient(0deg, rgba(225,225,225,1) 0%, rgba(255,255,255,1) 100%);   */
  height: 2.5rem;
  border: none;
  box-shadow: 0px 0px 5px;
}
.bi{
  /* text-shadow: 0px 0px 100px black; */
  cursor: pointer;
  font-size: 1.5rem; /* Altere o valor conforme desejado */ 
}
.navicon {
  font-size: 1.5rem; /* Altere o valor conforme desejado */
  color: rgb(160, 160, 160);
  text-shadow: none;
  margin: 5px;
}
#newNavBar .bi-chat-fill{
  /* color: rgb(175,161,14); */
  /* color: rgb(50, 50, 50); */
  color: var(--azul-1);
  border-bottom: 3px solid var(--azul-1);
}
#chatIcon{
  color: white;
}

.btn-success{
  text-align: left;
}

#MeusChatsContent{
    padding-top: 5rem;
    padding-bottom: 120%;
    display: grid;
    align-items: center;
    justify-content: center;
}

#chat{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    width: 100%;
    box-shadow: .5px .5px 5px black;
    /* background-color: rgb(160, 160, 160); */
    /* box-shadow: 1px 1px 1px black;
    text-shadow: 1px 1px 1px black; */
    /* background: rgb(37,157,29);
    background: linear-gradient(0deg, rgba(37,157,29,1) 0%, rgba(113,166,94,1) 100%); */
}
#del{
    margin-top: 0.5rem;
    width: 5rem;
    /* box-shadow: 1px 1px 1px black;
    text-shadow: 1px 1px 1px black;
    background: rgb(157,29,29);
    background: linear-gradient(0deg, rgba(157,29,29,1) 0%, rgba(166,94,96,1) 100%);  */
}

#assunto{
  overflow: hidden;
  font-size: 30px;
  text-shadow: .5px .5px 5px black;
}
</style>