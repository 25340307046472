<template>
  <div id="content" class="fade-in">
    
    <div id="topNavBar">
      <h3 id="appTitle">App Solidário</h3>
    </div>

    <div id="container">
      <p id="sobreTexto">Bem-vindo ao App Solidário - sua plataforma de conexão e apoio à comunidade! Nosso objetivo é promover a solidariedade e fazer a diferença na vida das pessoas. Com o App Solidário, você pode encontrar e participar de iniciativas locais, compartilhar recursos, oferecer ajuda e inspirar mudanças positivas em sua comunidade. Juntos, podemos criar um mundo mais solidário e inclusivo. Faça parte do movimento, faça a diferença - Baixe o App Solidário hoje mesmo!</p>
    </div>
      
    <div id="newNavBar">
          <router-link to="/home" class="nav-link"><i class="bi bi-house-fill navicon"></i></router-link>
          <router-link to="/meuschats" class="nav-link"><i class="bi bi-chat-fill navicon"></i></router-link>
          <router-link to="/postar" class="nav-link"><i class="bi bi-plus-square-fill navicon"></i></router-link>
          <router-link to="/perfil" class="nav-link"><i class="bi bi-person-fill navicon"></i></router-link>
          <router-link to="/sobre" class="nav-link"><i class="bi bi-info-square-fill navicon"></i></router-link>
    </div>

  </div>
</template>
  
<script>
import utils from '../utils';

  export default {
    name: "sobre",
  
    data: function() {
      return{
        
      }
    },
  
    methods: {
      Sair(){
        console.log("Cache limpo");
        localStorage.clear();
        this.$router.push("/");
      }
    },
  
    mounted: function(){
      utils.IsLogged();
    }
  };
</script>
  
<style scoped>
*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.fade-in {
  opacity: 0; /* Inicialmente, oculta o conteúdo */
  animation: fadeInAnimation 0.5s ease-in forwards; /* Animação de fade-in */
}
@keyframes fadeInAnimation {
  from {
    opacity: 0; /* Começa completamente transparente */
  }
  to {
    opacity: 1; /* Torna-se totalmente visível */
  }
}

#topNavBar{
  top: 0;
  z-index: 2;
  position: fixed;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-between; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  width: 100%;
  padding: 2.5px;
  /* background: rgb(8, 96, 211); */
  /* background-color: var(--azul-1); */
  background: rgb(24,62,159);
  /* background: linear-gradient(0deg, rgba(24,62,159,1) 0%, rgba(27,156,228,1) 100%);  */
  overflow: hidden;
  border: none;
  box-shadow: 0px 0px 5px;
}
#appTitle{
  color: white;
  text-shadow: 1px 1px 10px black;
  margin-left: 1.2rem;
}


#newNavBar{
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-around; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  /* background: rgb(167,167,167); */
  background: rgb(225, 225, 225);
  /* background: linear-gradient(0deg, rgba(225,225,225,1) 0%, rgba(255,255,255,1) 100%);   */
  height: 2.5rem;
  border: none;
  box-shadow: 0px 0px 5px;
}
.bi{
  /* text-shadow: 0px 0px 100px black; */
  cursor: pointer;
  font-size: 1.5rem; /* Altere o valor conforme desejado */ 
}
.navicon {
  font-size: 1.5rem; /* Altere o valor conforme desejado */
  color: rgb(160, 160, 160);
  text-shadow: none;
  margin: 5px;
}
.bi-info-square-fill{
  /* color: rgb(175,161,14); */
  /* color: rgb(50, 50, 50); */
  color: var(--azul-1);
  border-bottom: 3px solid var(--azul-1);
}
button{
  box-shadow: 1px 1px 1px black;
  text-shadow: 1px 1px 1px black;
  background: rgb(31,105,158);
  background: linear-gradient(0deg, rgba(31,105,158,1) 0%, rgba(111,179,227,1) 100%);
}

#content{
  background-color: rgb(228, 228, 228);
}

#container{
  padding-top: 5rem;
  padding-bottom: 70vh;
  text-align: justify;
  margin-left: 10%;
  margin-right: 10%;
}

#sobreTexto{
  font-size: 18px;
}
</style>