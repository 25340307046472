import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from "jquery";
import VueToastr from "vue-toastr";
import 'crypto-js';
import VueTheMask from 'vue-the-mask'
// import utils from './utils';
import 'bootstrap-icons/font/bootstrap-icons.css';

//  Adicione o utilitário ao protótipo do Vue
// Vue.prototype.$utils = utils;

//  Endereço do backend para comunicação com o servidor
//  Todas as solicitações são enviadas para Main.php, que executará cada
//  solicitação de acordo com seu código de operação.
// Vue.prototype.$backendDoServidor = 'http://localhost/App-Solid%c3%a1rio/vue-router/controller/Main.php';
// Vue.prototype.$imagesDoServidor = 'http://localhost/App-Solid%c3%a1rio/vue-router/images/';

Vue.prototype.$backendDoServidor = 'https://rillsoft-dev.cloud/controller/app-solidario/controller/Main.php';
Vue.prototype.$imagesDoServidor = 'https://rillsoft-dev.cloud/controller/app-solidario/images/';

window.$ = $;
Vue.use(VueTheMask)
Vue.config.productionTip = false;
Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
