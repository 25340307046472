<template>
    <div class="container fade-in">
        <div class="row" style="text-align: center; margin-top: 20%;">
            
            <div class="logo-header">
                <img id="appLogo" src="../assets/app_solidario_logo.png" style="width: 280px;"> 
            </div>
            
            <h1 id="title">
                Recuperar Login
            </h1>
            
            <p>Informe o email no qual deseja alterar a senha.</p>

            <!-- Grupos de input para email e código de recuperação. -->
            <!-- <div> -->
                <div class="input-group">
                    <!-- <label for="iEmail">Email</label> -->
                    <input id="iEmail" type="email" class="form-control" placeholder="Digite seu email" v-model="recover_email" required>
                </div>
                <div class="input-group">
                    <!-- <label for="iCR">Código</label> -->
                    <input id="iCR" type="text" class="form-control" placeholder="Digite seu código de recuperação" v-model="recover_code" required>
                </div>
                <div class="input-group">
                    <!-- <label for="ipassword">Senha</label> -->
                    <input id="ipassword" type="password" class="form-control" placeholder="Digite sua nova senha" v-model="passwordToUpdate" required>
                </div>
            <!-- </div> -->

            <!-- Botões para registrar e fazer o login. -->
            <div style="margin-top: 10%; padding: 10px;">
                <li class="list-group-item">
                    <button id="enviarButton" type="button" class="btn btn-success" @click="generateRecoverCode()">Enviar código <i class="bi bi-send-fill"></i></button>    
                </li>
                <li class="list-group-item">
                    <button id="autenticarButton" type="button" class="btn btn-primary" @click="autenticatePasswordChange()">Autenticar <i class="bi bi-key-fill"></i></button>
                </li>
                <li class="list-group-item">
                    <button id="cancelarButton" type="button" class="btn btn-danger" @click="voltarParaLogin()">Cancelar <i class="bi bi-x-circle-fill"></i></button>
                </li>         
            </div>

        </div>
    </div>

    
</template>

<script>
import axios from 'axios';

export default{
    name: 'loginrecover',

    data: function(){
        return{
            recover_email: "",
            passwordToUpdate: "",
            recover_code: "",
            recover_code_hash: ""
        }
    },

    methods: {
        //  Função para gerar hash, que no caso
        //  utiliza o SHA256 para gerar o hash.
        Hash(content){
            const CryptoJS = require("crypto-js");
            const hash = CryptoJS.SHA256(content);
            return hash.toString(CryptoJS.enc.Hex);
        },

        //  Função personalizada para exibição de alertas.
        Alert(msg, title, type){
            //this.$toastr.s(msg, title);
            if (type === "s"){
                this.$toastr.s(msg, title);
            }
            else if (type === "w"){
                this.$toastr.w(msg, title);
            }
            else if (type === "e"){
                this.$toastr.e(msg, title);
            }
        },

        // Função que gera um código de recuperação.
        generateRecoverCode(){
            //  Variável para tamanho do código.
            const codeSize = 16;
            //  Utilização de uma expressão regular para validar o formato do email.
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            let emailValido = regex.test(this.recover_email);
            if (emailValido === true){
                const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$%&_!?";
                let tempCode = "";
                for (let i = 0; i < codeSize; i++){
                    let randomIndex = Math.floor(Math.random() * chars.length);
                    tempCode += chars[randomIndex];
                }
                this.recover_code_hash = this.Hash(tempCode);
                // console.log(tempCode);
                // console.log(this.recover_code_hash);
            
                console.log("Enviando email...");

                const dataToSend = {
                    "opcode": 3,
                    "emailToSend": this.recover_email,
                    "recover_code": tempCode
                }

                let vself = this;
                axios.post(this.$backendDoServidor, dataToSend)
                    .then(response => {
                        console.log(response.data);
                        vself.Alert("Verifique sua caixa de email!", "Código enviado!", "s");
                    })
                    .catch(error => {
                    // Trate qualquer erro que possa ocorrer
                    console.error('Erro ao fazer requisição:', error);
                    });
            
                tempCode = "";
            }

        },

        // Função uqe vai autenticar a mudança de senha.
        autenticatePasswordChange(){
            if (this.Hash(this.recover_code) === this.recover_code_hash){
                console.log("Autenticação bem sucedida!");
                this.Alert("Autenticação bem sucedida!", "Aviso", "s");
                this.recover_code_hash = "";

                if (this.passwordToUpdate.length >= 8){
                    const securePassword = this.Hash(this.passwordToUpdate);
                    const dataToSend = {
                        "opcode": 4,
                        "emailToUpdate": this.recover_email,
                        "passwordToUpdate": securePassword
                    }
    
                    let vself = this;
                    axios.post(this.$backendDoServidor, dataToSend)
                        .then(response => {
                            console.log(response.data);
                            
                        })
                        .catch(error => {
                            // Trate qualquer erro que possa ocorrer
                            console.error('Erro ao fazer requisição:', error);
                        });

                    this.passwordToUpdate = "";
                    this.$router.push("/");
                }

            }
        },

        //  Volta para a página de login.
        voltarParaLogin(){
            this.$router.push("/");
        }
    },

    mounted: function(){
        // document.body.id = 'custom-body';
        // this.Alert("Informe o email que será recuperado!", "Atenção", "s");
    },

    computed:{

    }
}
</script>

<style scoped>
*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

:root {
    --azul-1: #183E9F;
    --azul-2: #1B9CE4;
}

.fade-in {
  opacity: 0; /* Inicialmente, oculta o conteúdo */
  animation: fadeInAnimation 0.5s ease-in forwards; /* Animação de fade-in */
}
@keyframes fadeInAnimation {
  from {
    opacity: 0; /* Começa completamente transparente */
  }
  to {
    opacity: 1; /* Torna-se totalmente visível */
  }
}

.logo-header{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.container{
    padding-top: 1rem;
    padding-bottom: 2rem;
    width: 100vw;
    background-color: rgb(228, 228, 228);
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 20px; */
}


input {
    border: 2px solid rgb(206, 206, 206);
    height: 45px;
    padding-inline: 10px;
    border-radius: 10px;
    transition: all 0.1s ease-in-out;
    margin-top: 10px;
}
input:focus {
    border: 2px var(--azul-1) solid; 
    outline: none;
}

button{
    width: 100%;
    height: 45px;
    margin-top: 20px;
    border: none;
    color: rgb(255, 255, 255);
    font-weight: 500;
    border-radius: 10px;
}

.btn-primary{
    background-color: var(--azul-1);
}

</style>