<template>
  <div id="content" class="fade-in">

  <div id="topNavBar">
    <h3 id="appTitle">App Solidário</h3>
    

    <div id="notificacoes">
      <i class="bi bi-bell-fill">
        <span id="notificacoesNumero" v-if="notificacoes > 0">{{ notificacoes }}</span>
      </i>
    </div>

    <div id="filtro">
      <div class="dropdown">
        <button id="filtrarButton" type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown">
          <i class="bi bi-funnel-fill"></i>
        </button>
        
        <ul class="dropdown-menu">
          <button id="itemFiltro" class="btn" @click='Filtrar("")'>Todos</button>
          <div v-for="mun in municipios">
            <button id="itemFiltro" class="btn" @click="Filtrar(mun)">{{ mun }}</button>
          </div>
        </ul>
      </div> 
    </div>
  </div>

  <!--Container de exibição das postagens-->
  <div class="postsContainer">
    <div id="container" v-for="(post, index) in postsToView" :key="index" >
      <ul class="list-group">
        <!--Situação-->
        <!-- <li class="list-group-item">
          <span class="badge bg-warning" v-if="post[13] === '0'">
            Situação:
              Em andamento
          </span>
          <span class="badge bg-success" v-else>
            Situação:
              Concluída
          </span>
        </li> -->

        <!--Exibição do ID da postagem-->
        <!-- <li class="list-group-item">Postagem: {{ post[0] }}</li> -->
        
        <!--Exibição do assunto da postagem-->
        <li id="idAssunto" class="badge">{{ post[1] }}</li>
        
        <!--Foto de perfil do usuário-->
        <li id="userInfo" class="list-group-item" style="display: flex; margin-left: 0px;"> 
          <img id="foto_perfil2" :src="$imagesDoServidor + post[17]" style="height: 30px; width: 30px;" @click="VerPerfil(post[2])">
          <span id="userName" style="margin-left: 10px; overflow: hidden;">{{ post[3] }}</span>

          <div class="reacoes">
            <div v-if="post[2] != iduser" @click="EnviarMensagem(post[0])"><i class="bi bi-send-fill reacoes"></i></div>
            <!-- <div @click="EnviarMensagem(post[0])"><i class="bi bi-cash-coin reacoes"></i></div>   -->
          </div>
        </li>

        <!--Slider com as imagens da postagem-->
        <div class="slider">
          <li v-if="post[5] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[5])"> <img class="slider-img" :src="$imagesDoServidor + post[5]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
          <li v-if="post[6] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[6])"> <img class="slider-img" :src="$imagesDoServidor + post[6]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
          <li v-if="post[7] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[7])"> <img class="slider-img" :src="$imagesDoServidor + post[7]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
          <li v-if="post[8] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[8])"> <img class="slider-img" :src="$imagesDoServidor + post[8]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
          <li v-if="post[9] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[9])"> <img class="slider-img" :src="$imagesDoServidor + post[9]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
        </div>

        <!-- <button class="btn btn-primary" @click="EnviarMensagem(post[0])">Enviar mensagem <i class="bi bi-send-fill"></i></button>  
        <button class="btn btn-primary" @click="EnviarMensagem(post[0])">Enviar mensagem <i class="bi bi-send-fill"></i></button> -->

        <!-- <li class="list-group-item reacoes"> -->
          <!-- <button class="btn btn-primary" @click="EnviarMensagem(post[0])">Mensagem <i class="bi bi-send-fill"></i></button>
          <button id="doarButton" class="btn btn-success" @click="EnviarMensagem(post[0])">Doação <i class="bi bi-cash-coin"></i></button> -->
          <!-- <div @click="EnviarMensagem(post[0])"><i class="bi bi-send-fill"></i></div>
          <div @click="EnviarMensagem(post[0])"><i class="bi bi-cash-coin"></i></div> -->
        
        <!-- </li> -->

        <!--Descrição da postagem-->
        <!-- <li id="idAssunto" class="list-group-item">Assunto: {{ post[1] }}</li> -->

        <!--Seção para votos-->
        <li class="list-group-item">
          <span>{{ post[16] }}</span>
          <i id="downVote" class="bi bi-arrow-down-short" @click="VotarNegativoNoPost(post[0])"></i>

          <span>{{ post[15] }}</span>
          <i id="upVote" class="bi bi-arrow-up-short" @click="VotarPositivoNoPost(post[0])"></i>
        </li>

        <li class="list-group-item">
          <!-- <textarea id="idAssunto" disabled>Assunto: {{ post[1] }}</textarea> -->
          <textarea id="descricao" disabled cols="30" rows=auto :value="post[10]"></textarea>
          <!-- <h6 id="descricao">{{post[10]}}</h6> -->
        </li>
        
        <!--Data de publicação da postagem-->
        <li class="list-group-item" style="text-align: left; font-size: 14px;">
          <i class="bi bi-clock"></i> Publicado há: {{ Tempo(post[14]) }} 
        </li>
      </ul>
    </div>
    
  </div>

  <!-- <div id="newNavBar">
    <a class="nav-link" href="/#home"><i class="bi bi-house-fill navicon"></i></a>
    <a class="nav-link" href="/#meuschats"><i class="bi bi-chat-fill navicon"></i></a>
    <a class="nav-link" href="/#postar"><i class="bi bi-plus-square-fill navicon"></i></a>  
    <a class="nav-link" href="/#perfil"><i class="bi bi-person-fill navicon"></i></a>
    <a class="nav-link" href="/#sobre"><i class="bi bi-info-square-fill navicon"></i></a>
  </div> -->

  <div id="newNavBar">
    <router-link to="/home" class="nav-link"><i class="bi bi-house-fill navicon"></i></router-link>
    <router-link to="/meuschats" class="nav-link"><i class="bi bi-chat-fill navicon"></i></router-link>
    <router-link to="/postar" class="nav-link"><i class="bi bi-plus-square-fill navicon"></i></router-link>
    <router-link to="/perfil" class="nav-link"><i class="bi bi-person-fill navicon"></i></router-link>
    <router-link to="/sobre" class="nav-link"><i class="bi bi-info-square-fill navicon"></i></router-link>
    <!-- <a class="nav-link" @click="Sair()"><i class="bi bi-box-arrow-left navicon"></i></a> -->
  </div>


  </div>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2'
import utils from '../utils';
import router from '../router';

export default{
    name: "home",

    data: function() {
      return{
        email: localStorage.getItem("session-email"),
        iduser: "",
        postsLimit: 25,
        offset: 0,
        limit: 25,
        postsToView: [],
        municipios: [],
        municipio: "",
        notificacoes: 0,
      }
    },

    methods: {
      //Funções de votar
      VotarPositivoNoPost(idpost){
        let dataToSend = {
          "opcode": 31,
          "idpost": idpost,
          "email": localStorage.getItem("session-email")
        }

        axios.post(this.$backendDoServidor, dataToSend)
          .then(response=>{
            console.log("votou!");
            location.reload();
          })
          .catch(err=>{
            console.error("Erro ao votar: "+err);
          })
      },
      VotarNegativoNoPost(idpost){
        let dataToSend = {
          "opcode": 32,
          "idpost": idpost,
          "email": localStorage.getItem("session-email")
        }

        axios.post(this.$backendDoServidor, dataToSend)
          .then(response=>{
            console.log("votou!");
            location.reload();
          })
          .catch(err=>{
            console.error("Erro ao votar: "+err);
          })
      },


      CountNewMessages(){
        let dataToSend = {
          "opcode": 27,
          "iduser": this.iduser
        }
        console.log(this.iduser);
        axios.post(this.$backendDoServidor, dataToSend)
          .then(response =>{
            console.log("Notificações ao todo: "+response.data);
            this.notificacoes = response.data;
          })
          .catch(err =>{
            console.error("Erro ao obter nº de notificações: "+err);
          })
      },

      VerPerfil(userID){
        if (userID != this.iduser){
          router.push({name: "verperfilusuario", params: {iduser: userID}});
        }
      },

      ZoomImage(imageURL){
        Swal.fire({
          imageUrl: imageURL,
        })
      },

      Tempo(value){
        return utils.TempoDePost(value);
      },

      Sair(){
        console.log("Cache limpo");
        localStorage.clear();
        router.push("/");
      },


      //  Implementação da rolagem infinita de tela
      MonitorarScroll() {
        // Verifica se o usuário chegou ao final da página
        if ((window.innerHeight + window.scrollY) >= (document.documentElement.scrollHeight * 1) ) {
          // Se o usuário chegou ao bottom da página, faça alguma coisa
          this.CarregarMaisPosts(); // Por exemplo, carregar mais posts
        }
      },

      CarregarMaisPosts() {
        // console.log("post carregado!");
        let dataToSend = {
          "opcode": 8,
          "municipio": this.municipio,
          "limit": this.limit,
          "offset": this.offset
        }

        axios.post(this.$backendDoServidor, dataToSend)
          .then(response =>{
            // console.log(response.data);
            for (let i = 0; i < response.data.length; i++){
              // console.log(response.data[i]);

              this.postsToView.push(response.data[i]);
              // if (!postsToView.some(post => post[0] === response.data[i][0])) {
              // }
            }
            // if (postsToView.length >= postsLimit){
            //   postsToView.splice(0, postsLimit);
            // }
          })
          .catch(err =>{
            console.log("Erro: ", err);
          })
          this.offset += this.limit;
          // console.log("offset:", offset);
          // console.log("Posts:", postsToView.length);
        },

        EnviarMensagem(value){
          // console.log(value);
          router.push({name: "initchat", params: {idpost: value}});
        },

        CarregarMunicipios(){
          let dataToSend = {
            "opcode": 19,
          }

          axios.post(this.$backendDoServidor, dataToSend)
            .then(response =>{
              // console.log(response.data);
              // municipios.push(response.data);
              for (let i = 0; i < response.data.length; i++){
                this.municipios.push(response.data[i][0])
                // console.log(municipios[i]);
              }
            })
            .catch(err =>{
              console.error("Erro ao carregar municípios: ", err)
            })
        },

        CarregarMunicipioUser(){
          let dataToSend = {
            "opcode": 20,
            "email": localStorage.getItem("session-email")
          }

          axios.post(this.$backendDoServidor, dataToSend)
            .then(response =>{
              console.log(response.data);
              this.Filtrar(response.data);
            })
            .catch(err =>{
              console.error("Erro ao carregar município do usuário: ", err);
            })
        },

        Filtrar(value){
          // console.log(value);
          this.municipio = value;
          this.postsToView = [];
          this.offset = 0;
          this.CarregarMaisPosts();
        },

        DescobrirID(){
          let dataToSend = {
            "opcode": 13,
            "email": this.email
          }

          axios.post(this.$backendDoServidor, dataToSend)
            .then(response =>{
              this.iduser = response.data;
              this.CountNewMessages();
            })
            .catch(err =>{
              console.error("Erro ao descobrir ID do usuário: "+err);
            })
        }
    },



    mounted: function(){
      utils.IsLogged();
      window.addEventListener('scroll', this.MonitorarScroll);
      this.CarregarMunicipios();
      this.CarregarMunicipioUser();
      this.DescobrirID();
      // this.CountNewMessages();
      //CarregarMaisPosts();
      // Verifica se o navegador suporta notificações
    },

    
};
</script>

<style scoped>
*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* border: 1px red solid;
  background-color: rgba(255, 0, 0, 0.142); */
}

.fade-in {
  opacity: 0; /* Inicialmente, oculta o conteúdo */
  animation: fadeInAnimation 0.5s ease-in forwards; /* Animação de fade-in */
}
@keyframes fadeInAnimation {
  from {
    opacity: 0; /* Começa completamente transparente */
  }
  to {
    opacity: 1; /* Torna-se totalmente visível */
  }
}

:root{
  --azul-1: #183E9F;
  --azul-2: #1B9CE4;
  --background-1: rgb(24,62,159);
  --gradient-1: linear-gradient(0deg, rgba(24,62,159,1) 0%, rgba(27,156,228,1) 100%);
}

.bi-clock{
  font-size: 10px !important;
}

#content{
  /* height: 100vh;
  width: 100vw; */
  background-color: rgb(228, 228, 228);
  /* background-color: black; */
} 

#topNavBar{
  top: 0;
  z-index: 2;
  position: fixed;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-between; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  width: 100%;
  padding: 2.5px;
  /* background: rgb(8, 96, 211); */
  background-color: var(--azul-1);
  /* background: rgb(24,62,159);
  background: linear-gradient(0deg, rgba(24,62,159,1) 0%, rgba(27,156,228,1) 100%);  */
  overflow: hidden;
  border: none;
  box-shadow: 0px 0px 5px;
}
#appTitle{
  color: white;
  /* text-shadow: 1px 1px 10px black; */
  margin-left: 1.2rem;
}

#notificacoes{
  color: white;
  margin-right: 5rem;
}

#filtro{
  /* margin-top: 0rem; */
  margin-right: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  position: fixed;
  right: 0;
}
#filtrarButton{
  background: transparent;
  /* background: rgb(225, 225, 225);
  background: linear-gradient(0deg, rgba(225,225,225,1) 0%, rgba(255,255,255,1) 100%); */
  border: none;
  color: white;
  /* box-shadow: 0.5px 0.5px 5px black; */
}
#itemFiltro{
  text-align: center;
  color: black;
  width: 100%;
}
.dropdown-menu{
  z-index: 1;
}

.badge{
  margin-left: auto;
  margin-right: auto;
  width: 20rem;
  overflow: auto;
}

#idAssunto{
  font-size: 20px;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  font-weight: 500;
  /* text-align: center; */
  resize: none; 
  border: none;
  background-color: var(--azul-1);
  /* background: rgb(24,62,159);
  background: linear-gradient(0deg, rgba(24,62,159,1) 0%, rgba(27,156,228,1) 100%);  */
  /* box-shadow: 0.5px 0.5px 5px; */
  border-radius: 10px;
}

#userInfo{
  display: flex;
  align-items: center;
  justify-content: left;
  border-top: none;
}
#userName{
  font-size: 15px;
  font-weight: 500;
}


.reacoes{
  margin-left: 4.5rem;
  display: flex; /* Torna os itens filhos flexíveis */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  justify-content: right; /* Distribui os itens uniformemente na linha */
  color: var(--azul-1);
}


#newNavBar{
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-around; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  /* background: rgb(167,167,167); */
  background: rgb(225, 225, 225);
  /* background: linear-gradient(0deg, rgba(225,225,225,1) 0%, rgba(255,255,255,1) 100%);   */
  height: 2.5rem;
  border: none;
  box-shadow: 0px 0px 5px;
}
.bi{
  /* text-shadow: 0px 0px 100px black; */
  cursor: pointer;
  font-size: 1.5rem; /* Altere o valor conforme desejado */ 
}
.navicon {
  font-size: 1.5rem; /* Altere o valor conforme desejado */
  color: rgb(160, 160, 160);
  text-shadow: none;
  margin: 5px;
}
.bi-house-fill{
  /* color: rgb(175,161,14); */
  /* color: rgb(50, 50, 50); */
  color: var(--azul-1);
  border-bottom: 3px solid var(--azul-1);
}
/* button{
  box-shadow: 1px 1px 1px black;
  text-shadow: 1px 1px 1px black;
  background: rgb(31,105,158);
  background: linear-gradient(0deg, rgba(31,105,158,1) 0%, rgba(111,179,227,1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
} */
.btn{
  background: rgb(225, 225, 225);
  background: linear-gradient(0deg, rgba(225,225,225,1) 0%, rgba(255,255,255,1) 100%);
  /* box-shadow: 1px 1px 5px black; */
  /* text-shadow: 0.5px 0.5px 0.5px black; */
}

#container{
  padding-bottom: 1rem;
  text-align: justify;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: rgb(225, 225, 225); */
}

/* .list-group{
  background: rgb(167,167,167);
  background: linear-gradient(0deg, rgba(167,167,167,1) 0%, rgba(255,255,255,1) 100%);
  border: 2px solid rgb(206, 206, 206);
  border-radius: 10px;
} */

.list-group{
  background: rgb(255,255,255);
  /* box-shadow: 0px 0px 5px black; */
  /* border: none; */
  border: 2px solid rgb(206, 206, 206);
  border-radius: 10px;
}
/* .list-group-item{
  background: rgb(227, 227, 227);
  border: none;
  border: 1px solid black;
} */

.li-img{
  border: none;
}

.slider{
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 20rem;
  max-height: 20rem;
  border: none;
}
.slider-img{
  height: 11rem !important;
  width: auto !important;
  background-size: contain !important;
  /* background-repeat: no-repeat; */
}

/* img{
  box-shadow: 2px 2px 2px black;
} */

#foto_perfil2{
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Torna a imagem redonda */
  object-fit: cover; /* Garante que a imagem cubra todo o espaço designado */
  display: block; /* Permite a centralização vertical */
}
.postsContainer{
  padding-top: 3.5rem;
  padding-bottom: 2rem;
}

/* #descricao{
  text-align: justify;
  resize: none; 
  color: black;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  height: auto;
  width: 10rem;
  overflow-y: scroll !important;
  scrollbar-width: auto !important;
  overflow: auto;
} */

#descricao{
  text-align: justify;
  resize: none; 
  color: black;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  height: auto;
}

/* Media query para telas menores que 600px */
@media screen and (min-width: 700px) {
  #container {
    font-size: 40px;      
  }
  #foto_perfil2{
    height: 250px;
    width: 250px;
  }
  .slider{
    max-width: 45rem;
    min-width: 45rem;
    max-height: 45rem;
  }
  .badge{
    max-width: 45rem;
    overflow: auto;
  }
}
/* #content{
  background-color: rgb(104, 100, 100);
} */


</style>