<template>
    <div class="container-login">
          <div class="header-login">
              <div class="logo-header">
                  <img v-bind:src="urlLogo" style="width: 240px;"> 
              </div>
              <div class="apresentacao">
                  <h1>Crie uma conta!</h1>
                  <p>Preencha com as suas informações continuar.</p>
              </div>
            </div>
          <div class="form-registro">
              <!-- <div class="form-usuario">
                  <label for="iUsuario">Criar nome de usuário</label>
                  <input type="text" name="iUsuario" id="iUsuario" v-model="vusuario" placeholder="Nome de usuário">
                  <small>Você pode alterá-lo quando quiser.</small>
              </div> -->
              
              <div class="form-email">
                  <label for="iEmail">Email</label>
                  <input type="text" name="iEmail" id="iEmail" v-model="vemail" placeholder="Digite seu email">
              </div>
              
              <div class="form-senha">
                  <label for="iSenha">Senha</label>
                  <input type="password" name="iSenha" id="iSenha" v-model="vsenha" placeholder="Digite sua senha">
              </div>
              
              <transition name="fade">
                  <div v-show="exibeConfirmaSenha" class="form-confirmarsenha">
                    <label for="iConfSenha">Confirme sua senha</label>
                    <input type="password" name="iConfSenha" id="iConfSenha" v-model="vconfirmarsenha" placeholder="Repita sua senha">
                    <small v-show="verificaSenha">As senhas devem ser iguais!</small>
                  </div>
              </transition>

              <button class="btn-submit" @click.prevent="RegistrarUsuario(vemail, vsenha)">
                  Criar minha conta <i class="bi bi-caret-right-fill"></i>
              </button>
          </div>

          <div class="footer-registro">
              <!-- <div class="footer-loginGoogle">
                  <span>ou</span>
                  <button>
                      <img src="../assets/google.svg" style="height: 20px; margin-right: 5px;">
                    Criar com Google
                  </button>
              </div> -->

              <div class="footer-registrar">
                  <span>Já possui uma conta? <RouterLink to="/loginnovo">Entrar</RouterLink></span>
              </div>
          </div>
      </div>
  </template>
  
  <script>
  import axios from "axios";
  import { error } from "jquery";
  import swal from 'sweetalert';
  
  export default {
    name: "RegistroView",
  
    //  Armazenamento de variáveis e valores.
    data: function(){
      return{
        // vusuario           : "",
        vemail          : "",
        vsenha          : "",
        vconfirmarsenha : "",

        urlLogo         : 'https://cdn.discordapp.com/attachments/972190453711634514/1240486339343024250/2.png?ex=6646bc6e&is=66456aee&hm=b3cc9489f199e616bd63901e85b770595da7cb7a4390580f6c7e62f8caf0606b&',
      }
    },
  
    /*
      Definição de funções que serão utilizadas
      de acordo com a necessidade.
    */
    methods: {
        Hash(content){
            const CryptoJS = require("crypto-js");
            const hash = CryptoJS.SHA256(content);
            return hash.toString(CryptoJS.enc.Hex);
        },

        FormatarDados(){
            this.vemail = ""
            this.vsenha = ""
        },

        RegistrarUsuario(email, senha){
        console.log("Teste registro no banco de dados.");
        
        //  Utilização de uma expressão regular para validar o formato do email.
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let emailValido = regex.test(email);

        if (emailValido === true && senha.length >= 8){
            //  Utilizar hash do tipo SHA-256 para guardar a senha no banco de dados.
            const securePassword = this.Hash(senha);
            const dataToSend = {
            "opcode": 1,
            "email": email,
            "senha": securePassword,
            };
    
            let vself = this;
            
            axios.post(this.$backendDoServidor, dataToSend)
            .then(response => {
                console.log(response.data);
                if (response.data === "O email já está cadastrado."){
                //vself.Alert(response.data, "Aviso", "e");
                swal( "O email já está cadastrado." ,  "" ,  "error" )
                }
                else{
                // Atribua a resposta aos dados do componente
                    
                //  Gerar cache para login automático
                vself.GenerateCache(email, senha);
                    
                console.log("Usuário cadastrado com sucesso!");
                //vself.Alert("Registro feito com sucesso!", "Sucesso", "s");
                swal( "Registro feito com sucesso!" ,  "" ,  "success" )
                vself.FormatarDados();
                }

            })
            .catch(error => {
                // Trate qualquer erro que possa ocorrer
                console.error('Erro ao fazer requisição:', error);
            });
        }
        else{
            //this.Alert("Credenciais inválidas!", "Atenção", "e");
            swal( "Credenciais inválidas!" ,  "" ,  "error" )
        }

        },

        LoginUsuario(email, senha){
        console.log("Teste login no banco de dados.");

        //  Utilizar hash do tipo SHA-256 para guardar a senha no banco de dados.
        const securePassword = this.Hash(senha);
        // if (this.isSHA256Hash(senha)){
        //   securePassword = senha;
        // }
        
        const dataToSend = {
            "opcode": 2,
            "email": email
        };

        let vself = this;
        
            axios.post(this.$backendDoServidor, dataToSend)
            .then(response => {

                if (response.data === "Email não cadastrado!"){
                //vself.Alert(response.data, "Aviso", "e");
                swal( "Email não cadastrado" ,  "" ,  "error" )
                }
                else{
                const senha_resposta = response.data[0]["senha"];
        
                console.log(senha_resposta);
                console.log(securePassword);
                if (securePassword === senha_resposta){
                    const dataToSend2 = {
                    "opcode": 5,
                    "email": email
                    }
                    
                    axios.post(this.$backendDoServidor, dataToSend2)
                    .then(response =>{
                        console.log(response.data[0].cadastro_finalizado);
                        const cadastro_finalizado = response.data[0].cadastro_finalizado;
                        if (cadastro_finalizado === "0"){
                        //  Redirecionar para a página de cadastro se necessário.
                        vself.$router.push({name: "editarperfil", params: {email: email}});
                        // vself.$router.push("/editarperfil");
                        swal("Termine de preencher o perfil");
                        }
                        else {
                        //  Redirecionar para a página de cadastro se necessário.
                        vself.$router.push("/home");
                        }
                    })
                    .catch(error =>{
                        console.log("Erro ao fazer a requisição: ", error);
                    })

                    //  Gerar cache para login automático
                    vself.GenerateCache(email, senha);
                    //vself.Alert("Login realizado!", "Bem-vindo!", "s");
                    swal( "Login realizado!" ,  "" ,  "success" )
                    // console.log("loign realizado");
                    vself.FormatarDados();
                }
                else{
                    // vself.Alert("Email ou senha inválidos!", "Erro!", "e");
                    swal("Email ou senha inválidos!", "", "error");
                    // console.log("Email ou senha inválidos!");
                }
                }

            })
            .catch(error => {
                // Trate qualquer erro que possa ocorrer
                console.error('Erro ao fazer requisição:', error);
            });
        },

        //  Ao ser cadastrado, o usuário gerará registros de cache
        GenerateCache(email, senha){
        localStorage.clear();
        localStorage.setItem("session-email", email);
        localStorage.setItem("session-password", senha);
        },
    },
    mounted: function(){
      //this.Alert('Bem Vindo!', 'Notificação', 's');
      // swal( "Bem Vindo!" ,  "" ,  "success" );
  
    },
  
    //  Manipulação de dados que serão alterados
    //  durante a execução e interação com o programa.
    computed:{
        verificaSenha(){
            if (this.vconfirmarsenha != ''){
                return this.vsenha != this.vconfirmarsenha
            }
        },
        exibeConfirmaSenha(){
            return this.vsenha != ''
        }
    }
  };
  
  </script>
  
  <style>
  *{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  :root {
      --azul-1: #183E9F;
      --azul-2: #1B9CE4;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  .container-login {
      min-height: 100vh;
      height: fit-content;
      width: 100vw;
      background-color: rgb(228, 228, 228);
      padding: 20px;
  }
  .logo-header{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 15px;
  }
  .apresentacao{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .apresentacao h1{
      font-size: 25px
  }
  .apresentacao p{
      font-size: 15px;
  }
  .form-usuario {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
  }
  .form-usuario label {
      margin-left: 5px;
  }
  .form-usuario input {
      border: 2px solid rgb(206, 206, 206);
      height: 45px;
      padding-inline: 10px;
      border-radius: 10px;
      transition: all 0.1s ease-in-out;
  }
  .form-usuario input:focus {
      border: 2px var(--azul-1) solid; 
      outline: none;
    }
.form-usuario small {
    font-size: 12px;
    color: #505050;
    padding-left: 5px;
}

.form-email {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
.form-email label {
    margin-left: 5px;
}
.form-email input {
    border: 2px solid rgb(206, 206, 206);
    height: 45px;
    padding-inline: 10px;
    border-radius: 10px;
    transition: all 0.1s ease-in-out;
}
.form-email input:focus {
    border: 2px var(--azul-1) solid; 
    outline: none;
}
.form-senha {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.form-senha label {
      margin-left: 5px;
  }
.form-senha input {
    height: 45px;
    border: 2px solid rgb(206, 206, 206);
    padding-inline: 10px;
    border-radius: 10px;
    color: var(--azul-1);
    transition: all 0.1s ease-in-out;
}
.form-senha input:focus {
    border: 2px var(--azul-1) solid; 
    outline: none;
}
.form-confirmarsenha {
    display: flex;
    flex-direction: column;
}
.form-confirmarsenha label {
      margin-left: 5px;
  }
.form-confirmarsenha input {
    height: 45px;
    border: 2px solid rgb(206, 206, 206);
    padding-inline: 10px;
    border-radius: 10px;
    transition: all 0.1s ease-in-out;
}
.form-confirmarsenha small {
    font-size: 12px;
    color: #890000;
    padding-left: 5px;
}

.form-confirmarsenha input:focus {
    border: 2px var(--azul-1) solid; 
    outline: none;
}

.btn-submit {
    width: 100%;
    height: 45px;
    margin-top: 20px;
    border: none;
    color: rgb(255, 255, 255);
    font-weight: 500;
    background-color: var(--azul-1);
    border-radius: 10px;
}

.footer-registro {
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.footer-loginGoogle{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.footer-loginGoogle span{
    margin-block: 10px;
}
.footer-loginGoogle button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    font-weight: 500;
    border-radius: 10px;
    border: 2px solid rgb(206, 206, 206);
    background-color: rgb(255, 255, 255);
}

.footer-registrar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    margin-top: 20px;
}
.footer-registrar a {
    font-weight: 500;
    text-decoration: none;
    color: var(--azul-1)
}
</style>
  