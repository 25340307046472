<template>
    <div id="content" class="fade-in">
        <!--TopNavBar-->
        <div id="topNavBar">
            <h3 id="appTitle">App Solidário</h3>
        </div>

        <div id="container">

            <!--Informações principais-->
            <div id="perfilUsuario">
                <div id="fotoNome" class="row">
                  <div class="col">
                    <img id="foto_perfil" v-if="foto_perfil" :src="$imagesDoServidor + foto_perfil" alt="Foto de perfil" accept="image/*">
                  
                    <h1 id="nomeDeUsuario">{{ nome }}</h1>
                  </div>
                </div>
    
    
                <div id="informacoesDoUsuario" class="form">
    
    
                  <div class="row">
                    <!-- <label for="a">CEP</label> -->
                    <div class="col">
                      <p id="a" class="badge bg-primary">CEP: {{ cep }}</p>
                    </div>
                  </div>
                  
                  <div class="row">
                    <!-- <label for="">Logradouro</label> -->
                    <div class="col">
                      <p class="badge bg-primary">Logradouro: {{ logradouro }}</p>
                    </div>
                  </div>
    
                  <div class="row">
                    <!-- <label for="">Bairro</label> -->
                    <div class="col">
                      <p class="badge bg-primary">Bairro: {{ bairro }}</p>
                    </div>
                  </div>
    
                  <div class="row">
                    <!-- <label for="">UF</label> -->
                    <div class="col">
                      <p class="badge bg-primary">Unidade Federativa: {{ uf }}</p>
                    </div>
                  </div>
                </div>
            </div>

            <h1>Postagens:</h1>
            <div class="postsContainer">
              <div id="container" v-for="(post, index) in postsToView" :key="index" >
                <ul class="list-group">
                  <!--Exibição do assunto da postagem-->
                  <li id="idAssunto" class="badge">{{ post[1] }}</li>
                  
                  <!--Foto de perfil do usuário-->
                  <li id="userInfo" class="list-group-item" style="display: flex; margin-left: 0px;"> 
                    <img id="foto_perfil2" :src="$imagesDoServidor + post[15]" style="height: 30px; width: 30px;">
                    <span id="userName" style="margin-left: 10px; overflow: hidden;">{{ post[3] }}</span>
                  </li>

                  <!--Slider com as imagens da postagem-->
                  <div class="slider">
                    <li v-if="post[5] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[5])"> <img class="slider-img" :src="$imagesDoServidor + post[5]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                    <li v-if="post[6] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[6])"> <img class="slider-img" :src="$imagesDoServidor + post[6]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                    <li v-if="post[7] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[7])"> <img class="slider-img" :src="$imagesDoServidor + post[7]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                    <li v-if="post[8] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[8])"> <img class="slider-img" :src="$imagesDoServidor + post[8]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                    <li v-if="post[9] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[9])"> <img class="slider-img" :src="$imagesDoServidor + post[9]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                  </div>

                  <!--Descrição da postagem-->
                  <!-- <li id="idAssunto" class="list-group-item">Assunto: {{ post[1] }}</li> -->
                  <li class="list-group-item">
                    <!-- <textarea id="idAssunto" disabled>Assunto: {{ post[1] }}</textarea> -->
                    <textarea id="descricao" disabled cols="30" rows=auto :value="post[10]"></textarea>
                  </li>
                  
                  <!--Data de publicação da postagem-->
                  <!-- <li class="list-group-item" style="text-align: left; font-size: 14px;">Publicado há: {{ Tempo(post[14]) }} </li> -->
                  <li class="list-group-item" style="text-align: left; font-size: 14px;">Publicado há: {{ Tempo(post[14]) }} </li>
                </ul>
              </div>
              
            </div>
        </div>
        <!--NewNavBar-->
        <div id="newNavBar">
            <router-link to="/home" class="nav-link"><i class="bi bi-house-fill navicon"></i></router-link>
            <router-link to="/meuschats" class="nav-link"><i class="bi bi-chat-fill navicon"></i></router-link>
            <router-link to="/postar" class="nav-link"><i class="bi bi-plus-square-fill navicon"></i></router-link>
            <router-link to="/perfil" class="nav-link"><i class="bi bi-person-fill navicon"></i></router-link>
            <router-link to="/sobre" class="nav-link"><i class="bi bi-info-square-fill navicon"></i></router-link>
            <!-- <a class="nav-link" @click="Sair()"><i class="bi bi-box-arrow-left navicon"></i></a> -->
        </div>


    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import utils from '../utils';

export default {
    name: "verperfilusuario",

    data: function(){
        return{
            iduser: this.$route.params.iduser,
            nome: "",
            cep: "",
            logradouro: "",
            bairro: "",
            municipio: "",
            uf: "",
            foto_perfil: "",
            postsToView: [],
            offset: 0,
            limit: 10,
        }
    },

    methods:{
        ZoomImage(imageURL){
          Swal.fire({
            imageUrl: imageURL,
          })
        },
        Tempo(value){
            return utils.TempoDePost(value);
        },
        CarregarInformacoes(){
            const dataToSend = {
                "opcode": 28,
                "iduser": parseInt(this.iduser) 
            }
            // console.log("iduser: "+this.iduser);
            axios.post(this.$backendDoServidor, dataToSend)
                .then(response => {
                    console.log(response.data)
                    this.nome = response.data[0][8];
                    this.cep = response.data[0]["cep"];
                    this.logradouro = response.data[0]["logradouro"];
                    this.bairro = response.data[0]["bairro"];
                    this.municipio = response.data[0]["municipio"];
                    this.uf = response.data[0]["uf"];
                    this.foto_perfil = response.data[0]["foto_perfil"];
                    // this.iduser = response.data[0]["id"]
                    // console.log(this.iduser);
                })
                .catch(err => {
                    console.log("Erro de requisição: ", err);
                })

            console.log("Informações do usuário carregadas!");
        },

        MonitorarScroll() {
            // Verifica se o usuário chegou ao final da página
            if ((window.innerHeight + window.scrollY) >= (document.documentElement.scrollHeight * 1) ) {
            // Se o usuário chegou ao bottom da página, faça alguma coisa
            this.CarregarPosts(); // Por exemplo, carregar mais posts
            }
        },

        CarregarPosts() {
            console.log("Carregando posts do usuário!");

            let dataToSend = {
                "opcode": 29,
                "iduser": this.iduser,
                "limit": this.limit,
                "offset": this.offset
            }

            axios.post(this.$backendDoServidor, dataToSend)
            .then(response =>{
                console.log(response.data);
                for (let i = 0; i < response.data.length; i++){
                // console.log(response.data[i]);

                    this.postsToView.push(response.data[i]);
                // if (!this.postsToView.some(post => post[0] === response.data[i][0])) {
                // }
                }
                // if (this.postsToView.length >= this.postsLimit){
                //   this.postsToView.splice(0, this.postsLimit);
                // }
            })
            .catch(err =>{
                console.log("Erro: ", err);
            })
            this.offset += this.limit;
            // console.log("offset:", this.offset);
            // console.log("Posts:", this.postsToView.length);
        },
    },

    mounted: function(){
        window.addEventListener('scroll', this.MonitorarScroll);
        this.CarregarInformacoes();
        this.CarregarPosts();
    },

    computed:{
    }
}
</script>

<style scoped>
*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* border: 1px red solid;
  background-color: rgba(255, 0, 0, 0.142); */
}

.fade-in {
  opacity: 0; /* Inicialmente, oculta o conteúdo */
  animation: fadeInAnimation 0.5s ease-in forwards; /* Animação de fade-in */
}
@keyframes fadeInAnimation {
  from {
    opacity: 0; /* Começa completamente transparente */
  }
  to {
    opacity: 1; /* Torna-se totalmente visível */
  }
}

:root{
  --azul-1: #183E9F;
  --azul-2: #1B9CE4;
  --background-1: rgb(24,62,159);
  --gradient-1: linear-gradient(0deg, rgba(24,62,159,1) 0%, rgba(27,156,228,1) 100%);
}

#topNavBar{
  top: 0;
  z-index: 2;
  position: fixed;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-between; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  width: 100%;
  padding: 2.5px;
  /* background: rgb(8, 96, 211); */
  background-color: var(--azul-1);
  /* background: rgb(24,62,159);
  background: linear-gradient(0deg, rgba(24,62,159,1) 0%, rgba(27,156,228,1) 100%);  */
  overflow: hidden;
  border: none;
  box-shadow: 0px 0px 5px;
}
#appTitle{
  color: white;
  /* text-shadow: 1px 1px 10px black; */
  margin-left: 1.2rem;
}

#newNavBar{
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-around; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  /* background: rgb(167,167,167); */
  background: rgb(225, 225, 225);
  /* background: linear-gradient(0deg, rgba(225,225,225,1) 0%, rgba(255,255,255,1) 100%);   */
  height: 2.5rem;
  border: none;
  box-shadow: 0px 0px 5px;
}
.bi{
  /* text-shadow: 0px 0px 100px black; */
  cursor: pointer;
  font-size: 1.5rem; /* Altere o valor conforme desejado */ 
}
.navicon {
  font-size: 1.5rem; /* Altere o valor conforme desejado */
  color: rgb(160, 160, 160);
  text-shadow: none;
  margin: 5px;
}
.bi-house-fill{
  /* color: rgb(175,161,14); */
  /* color: rgb(50, 50, 50); */
  color: var(--azul-1);
  border-bottom: 3px solid var(--azul-1);
}

.badge{
  margin-left: 1px;
  margin-right: 1px;
}

#idAssunto{
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-align: center;
  resize: none; 
  border: none;
  background-color: var(--azul-1);
  /* background: rgb(24,62,159);
  background: linear-gradient(0deg, rgba(24,62,159,1) 0%, rgba(27,156,228,1) 100%);  */
  /* box-shadow: 0.5px 0.5px 5px; */
  border-radius: 10px;
}

#foto_perfil2{
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Torna a imagem redonda */
  object-fit: cover; /* Garante que a imagem cubra todo o espaço designado */
  display: block; /* Permite a centralização vertical */
}

#foto_perfil{
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Torna a imagem redonda */
  object-fit: cover; /* Garante que a imagem cubra todo o espaço designado */
  display: block; /* Permite a centralização vertical */
  margin: auto; /* Centraliza horizontalmente */
}

#content{
    padding-top: 3rem;
}

#container{
  padding-top: 3rem;
  padding-bottom: 2rem;
  text-align: justify;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: rgb(225, 225, 225); */
}

#fotoNome{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#nomeDeUsuario{
  margin-top: 0.5rem;
  font-size: 2rem;
}

#informacoesDeUsuario{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.postsContainer{
  padding-top: 3rem;
  padding-bottom: 1rem;
}

#descricao{
  text-align: justify;
  resize: none; 
  color: black;
  border: none;
  border-radius: 10px;
  background-color: transparent;
}

.bg-warning{
  font-size: 15px;
}
.bg-success{
  font-size: 15px;
}

.li-img{
  border: none;
}
.slider{
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 20rem;
  max-height: 20rem;
  border: none;
}
.slider-img{
  height: 11rem !important;
  width: auto !important;
  background-size: contain !important;
  /* background-repeat: no-repeat; */
}
/* Media query para telas menores que 600px */
@media screen and (min-width: 700px) {
  #container {
      font-size: 30px;      
  }
  #foto_perfil2{
    height: 250px;
    width: 250px;
  }
  .slider{
    max-width: 40rem;
    min-width: 40rem;
    max-height: 40rem;
  }
}

#actions{
  margin-left: 2.5rem;
}
#actions button{
  margin-right: 1rem;
}
#actions i{
  font-size: 16px;
}
</style>