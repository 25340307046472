<template>
    <div id="content" class="fade-in">

        <div id="topNavBar">
          <h3 id="appTitle">App Solidário</h3>

          <button id="returnButton" @click="Cancelar">
            <i class="bi bi-arrow-return-left"></i>
          </button>
        </div>
    
        <div id="container">
          
          <form class="form-editar-perfil">
            <div id="fotoDePerfil" class="row">
                <div class="col">
                  <img id="foto_perfil" v-if="foto_perfil" :src="this.$imagesDoServidor + foto_perfil" alt="" @click="SelecionarFotoPerfil" accept="image/*">
                  <input ref="meuInput" type="file" v-show="false" @change="handleFileInputChange"> 
                  <!-- <i class="bi bi-pencil-square"></i> -->
                </div>
              </div>

              <div id="dadosPt1" class="row">
                
                <div class="row" id="nomeCpf">
                  <div class="col">
                    <label for="nomeInput">Nome</label>
                    <input id="nomeInput" type="text" class="form-control" placeholder="nome completo" v-model="nome" required>
                    
                    <label for="cepInput">CEP</label>
                    <input id="cepInput" type="text" class="form-control" placeholder="cep" v-model="cep" required @blur="confirmarCadastro()" v-mask="'#####-###'">

                  </div>
                </div>

                
                <div class="col">
                  <label for="logradouroInput">Logradouro</label>
                  <input id="logradouroInput" type="text" class="form-control" placeholder="logradouro" v-model="logradouro" required>

                  <label for="municipioInput">Município</label>
                  <input id="municipioInput" type="text" class="form-control" placeholder="município" v-model="municipio" required>  
                </div>

                <div id="dadosPt2" class="col">
                
                  <label for="bairroInput">Bairro</label>
                  <input id="bairroInput" type="text" class="form-control" placeholder="bairro" v-model="bairro" required>

                  <label for="ufInput">UF</label>
                  <input id="ufInput" type="text" class="form-control" placeholder="unidade federativa" v-model="uf" required>
                </div>
              </div>
              
              <div id="emailSenhaLogin" class="row">
                  <div class="col">
                    <h1>Informações de login</h1>

                    <label for="emailInput">Email</label>
                    <input id="emailInput" type="text" class="form-control" placeholder="email" v-model="email" required>
                    
                    <label for="passwordInput">Senha</label>
                    <input id="passwordInput" type="password" class="form-control" placeholder="senha" v-model="senha" required>
                  </div>
              </div>
            </form>

            <div id="editarPerfilButtons" class="row">
                <div class="col">
                  <button type="button" class="btn btn-success" @click="finalizarCadastro">Salvar <i class="bi bi-floppy-fill editarPefilIcons"></i></button>
                  <button type="button" class="btn btn-danger" @click="Cancelar">Cancelar <i class="bi bi-x-circle-fill editarPefilIcons"></i></button>
                  <button type="button" class="btn btn-dark" @click="ApagarConta">Apagar Conta <i class="bi bi-trash3-fill editarPefilIcons"></i></button>
                </div>
            </div>
        </div>

        <!-- <div id="newNavBar">
            <a class="nav-link" href="/home"><i class="bi bi-house-fill navicon"></i></a>
            <a class="nav-link" href="/meuschats"><i class="bi bi-chat-fill navicon"></i></a>
            <a class="nav-link" href="/postar"><i class="bi bi-plus-square-fill navicon"></i></a>  
            <a class="nav-link" href="/perfil"><i class="bi bi-person-fill navicon"></i></a>
            <a class="nav-link" href="/sobre"><i class="bi bi-info-square-fill navicon"></i></a>
            <a class="nav-link" @click="Sair()"><i class="bi bi-box-arrow-left navicon"></i></a>
        </div> -->

    </div>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
import utils from '../utils';

export default {
  name: "perfil",

  data: function() {
    return{
      emailCache: localStorage.getItem("session-email"),
      passwordCache: localStorage.getItem("session-password"),
      email: "",
      senha: "",
      foto_perfil: "",
      nome: "",
      cep: "",
      logradouro: "",
      bairro: "",
      municipio: "",
      uf: ""
    }
  },

  methods: {
    //  Ao ser cadastrado, o usuário gerará registros de cache
    GenerateCache(email, senha){
      localStorage.clear();
      localStorage.setItem("session-email", email);
      localStorage.setItem("session-password", senha);
    },

    //  Função para gerar hash, que no caso
    //  utiliza o MD5 para gerar o hash.
    Hash(content){
      const CryptoJS = require("crypto-js");
      const hash = CryptoJS.SHA256(content);
      return hash.toString(CryptoJS.enc.Hex);
    },

    UploadProfilePicture(){
      let dataToSend = {
        "opcode": 26,
        "email": localStorage.getItem("session-email"),
        "foto_perfil": this.foto_perfil
      }

      axios.post(this.$backendDoServidor, dataToSend)
        .then(response =>{
          console.log("Foto de perfil atualizada!");
        })
        .catch(err =>{
          console.error("Erro ao atuzalizar foto de perfil: ", err);
        })
    },

    handleFileInputChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        // Quando o arquivo é carregado, converte o conteúdo em Base64
        this.foto_perfil = reader.result;
        this.UploadProfilePicture();
      };
      // Lê o conteúdo do arquivo como URL de dados
      reader.readAsDataURL(file);
    },

    SelecionarFotoPerfil() {
      // Simula um clique no input file
      this.$refs.meuInput.click();
    },

    CarregarInformacoes(){
      const dataToSend = {
        "opcode": 7,
        "email": this.emailCache 
      }
      axios.post(this.$backendDoServidor, dataToSend)
        .then(response => {
          console.log(response.data)
          this.nome = response.data[0][8];
          this.cep = response.data[0]["cep"];
          this.logradouro = response.data[0]["logradouro"];
          this.bairro = response.data[0]["bairro"];
          this.municipio = response.data[0]["municipio"];
          this.uf = response.data[0]["uf"];
          this.foto_perfil = response.data[0]["foto_perfil"];
          this.email = this.emailCache;
          this.senha = this.passwordCache;
        })
        .catch(err => {
          console.log("Erro de requisição: ", err);
        })

        console.log("Informações carregadas!");
    },

    confirmarCadastro(){
        let cep = this.cep;
        const url = `https://viacep.com.br/ws/${cep}/json/`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                //  console.log(data);
                //  Faça o que quiser com os dados do endereço aqui
                //  console.log(data.logradouro);
                this.logradouro = data.logradouro;
                this.bairro = data.bairro;
                this.municipio = data.localidade;
                this.uf = data.uf;
            })
            .catch(error => console.error('Erro ao buscar CEP:', error));
    },

    finalizarCadastro(){
        if (this.nome && this.cep && this.logradouro && this.bairro && this.municipio && this.uf){
            //console.log("Olá, Mundo!");

            const dataToSend = {
                "opcode": 6,
                "email": this.emailCache,
                "nome": this.nome,
                "cep": this.cep,
                "logradouro": this.logradouro,
                "bairro": this.bairro,
                "municipio": this.municipio,
                "uf": this.uf,
                "foto_perfil": this.foto_perfil,
                "novo_email": this.email,
                "nova_senha": this.Hash(this.senha)
            }
            let vself = this;
            axios.post(this.$backendDoServidor, dataToSend)
                .then(response =>{
                    console.log(response.data);
                    vself.GenerateCache(this.email, this.senha);
                    vself.$router.push("/perfil");
                    swal("Perfil atualizado!", "", "success");
                })
                .catch(error =>{
                    console.log("Erro de requisição: ", error);
                })
        }

        else{
          swal("Cadastro incompleto!", "Complete seu cadastro para poder salvar suas informações!", "error");
        }
    },

    Sair(){
        console.log("Cache limpo");
        localStorage.clear();
        this.$router.push("/");
    },

    Cancelar(){
        if (this.nome && this.cep && this.logradouro && this.bairro && this.municipio && this.uf){
          this.$router.push("/perfil");
        }
        else{
          swal("Cadastro incompleto!", "Complete seu cadastro!", "error");
        }
    },

    ApagarConta(){

      swal({
        title: "Tem certza que deseja excluir a conta?",
        text: "Uma vez deletada, a conta não poderá ser recuperada!",
        icon: "warning",
        buttons: ["Não, cancelar!", "Excluir conta!"],
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          console.log("Apagando conta do usuário...");
          let dataToSend = {
            "opcode": 25,
            "email": localStorage.getItem("session-email")
          }
    
          axios.post(this.$backendDoServidor, dataToSend)
            .then(response =>{
              console.log(response.data)
              this.Sair();
            })
            .catch(err =>{
              console.error(err)
            })
        } 
        
        else {
          console.log("Operação cancelada");
          swal("Operação cancelada");
        }
      });

    }
  },

  mounted: function(){
    utils.IsLogged();
    
    this.CarregarInformacoes();
  }
};
</script>

<style scoped>
*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

:root {
    --azul-1: #183E9F;
    --azul-2: #1B9CE4;
}

.fade-in {
  opacity: 0; /* Inicialmente, oculta o conteúdo */
  animation: fadeInAnimation 0.5s ease-in forwards; /* Animação de fade-in */
}
@keyframes fadeInAnimation {
  from {
    opacity: 0; /* Começa completamente transparente */
  }
  to {
    opacity: 1; /* Torna-se totalmente visível */
  }
}

#topNavBar{
  top: 0;
  z-index: 2;
  position: fixed;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-between; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  width: 100%;
  padding: 2.5px;
  /* background: rgb(8, 96, 211); */
  /* background-color: var(--azul-1); */
  background: rgb(24,62,159);
  /* background: linear-gradient(0deg, rgba(24,62,159,1) 0%, rgba(27,156,228,1) 100%);  */
  overflow: hidden;
  border: none;
  box-shadow: 0px 0px 5px;
}
#appTitle{
  color: white;
  text-shadow: 1px 1px 10px black;
  margin-left: 1.2rem;
}
#returnButton{
  background-color: transparent;
  border: none;
  color: white;
}

#content{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(228, 228, 228);
  padding-bottom: 10vh;
}

#fotoDePerfil{
  text-align: center;
  padding: 1rem;
}

#nomeCpf{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

#newNavBar{
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-around; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  /* background: rgb(167,167,167); */
  background: rgb(225, 225, 225);
  /* background: linear-gradient(0deg, rgba(225,225,225,1) 0%, rgba(255,255,255,1) 100%);   */
  height: 2.5rem;
  border: none;
  box-shadow: 0px 0px 5px;
}
.bi {
  /* text-shadow: 0px 0px 100px black; */
  cursor: pointer;
  font-size: 1.5rem; /* Altere o valor conforme desejado */ 
}
.navicon {
  font-size: 1.5rem; /* Altere o valor conforme desejado */
  color: rgb(160, 160, 160);
  text-shadow: none;
  margin: 5px;
}
.bi-person-fill{
  /* color: rgb(175,161,14); */
  /* color: rgb(50, 50, 50); */
  color: var(--azul-1);
  border-bottom: 3px solid var(--azul-1);
}


#emailSenhaLogin{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.btn{
    width: 100%;
    height: 45px;
    margin-top: 0.25rem;
    border: none;
    color: rgb(255, 255, 255);
    font-weight: 500;
    border-radius: 10px;
}


.editarPefilIcons{
  /* font-weight: 500; */
  font-size: 15px;
}

#container{
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  text-align: justify;
  margin-left: 10%;
  margin-right: 10%;
}

input[type="file"] {
      color: transparent;
}

#foto_perfil{
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Torna a imagem redonda */
  object-fit: cover; /* Garante que a imagem cubra todo o espaço designado */
  display: block; /* Permite a centralização vertical */
  margin: auto; /* Centraliza horizontalmente */
}

button{
  margin-top: 0.25rem;
}

input{
    margin-top: 0.25rem;
    height: 45px;
    border: 2px solid rgb(206, 206, 206);
    padding-inline: 10px;
    border-radius: 10px;
    transition: all 0.1s ease-in-out;
}
input:focus{
    border: 2px var(--azul-1) solid; 
    outline: none;
}
</style>