<template>
  <div class="container-login fade-in">
        <div class="header-login">
            <div class="logo-header">
                <img id="appLogo" src="../assets/app_solidario_logo.png" style="width: 280px;"> 
            </div>
            <div class="apresentacao">
                <h1>Bem-vindo!</h1>
                <p>Faça login para continuar.</p>
            </div>
          </div>
        <div class="form-registro">
            <div class="form-email">
                <label for="iEmail">Email</label>
                <input type="text" name="iEmail" id="iEmail"  v-model="email" placeholder="Digite seu email">
            </div>
            <div class="form-senha">
                <label for="iSenha">Senha</label>
                <input type="password" name="iSenha" id="iSenha" v-model="senha" placeholder="Digite sua senha">
            </div>
            <div class="form-recoverpass">
                <!-- <a href="LoginRecover">Esqueceu sua senha?</a> -->
                <router-link to="LoginRecover">Esqueceu sua senha?</router-link>

            </div>
            <button class="btn-submit" @click="LoginUsuario(email, senha)">
                Entrar <i class="bi bi-caret-right-fill"></i>
            </button>
        </div>
        <div class="footer-registro">
            <!-- <div class="footer-loginGoogle">
                <span>ou</span>
                <button>
                    <img src="../assets/google.svg" style="height: 20px; margin-right: 5px;">
                    Continuar com Google
                </button>
            </div> -->
            <div class="footer-registrar">
                <span>Não possui uma conta? <RouterLink to="/registroviewnovo">Registrar</RouterLink></span>
            </div>
          </div>
    </div>
</template>

<script>
import axios from "axios";
import { error } from "jquery";
import swal from 'sweetalert';

export default {
  name: "login",

  //  Armazenamento de variáveis e valores.
  data: function(){
    return{
      email: "",
      senha: "",
      urlLogo: 'https://cdn.discordapp.com/attachments/972190453711634514/1240486339343024250/2.png?ex=6646bc6e&is=66456aee&hm=b3cc9489f199e616bd63901e85b770595da7cb7a4390580f6c7e62f8caf0606b&',
    }
  },

  /*
    Definição de funções que serão utilizadas
    de acordo com a necessidade.
  */
  methods: {
    isSHA256Hash(text) {
      // Verifica o comprimento do texto
      if (text.length !== 64) {
          return false;
      }
      // Verifica se todos os caracteres são hexadecimais
      if (!/^[a-fA-F0-9]*$/.test(text)) {
          return false;
      }
      return true;
    },

    Random(number1, number2){
      return Math.floor(Math.random() * number2) + number1;
    },

    //  Função para gerar hash, que no caso
    //  utiliza o MD5 para gerar o hash.
    Hash(content){
      const CryptoJS = require("crypto-js");
      const hash = CryptoJS.SHA256(content);
      return hash.toString(CryptoJS.enc.Hex);
    },

    //  Função personalizada para exibição de alertas.
    Alert(msg, title, type){
      //this.$toastr.s(msg, title);
      if (type === "s"){
        this.$toastr.s(msg, title);
      }
      else if (type === "w"){
        this.$toastr.w(msg, title);
      }
      else if (type === "e"){
        this.$toastr.e(msg, title);
      }
    },

    //  Função de teste.
    HelloWorld(){
      this.Alert('Registro processado com sucesso!', 'Informação!');
    },

    //  Função/Método para formatar/limpar os dados.
    FormatarDados(){
      this.email = ""
      this.senha = ""
    },

    //  Implementação da função de registrar no banco de dados com php.
    RegistrarUsuario(email, senha){
      console.log("Teste registro no banco de dados.");
      
      //  Utilização de uma expressão regular para validar o formato do email.
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let emailValido = regex.test(email);

      if (emailValido === true && senha.length >= 8){
        //  Utilizar hash do tipo SHA-256 para guardar a senha no banco de dados.
        const securePassword = this.Hash(senha);
        const dataToSend = {
          "opcode": 1,
          "email": email,
          "senha": securePassword,
        };
  
        let vself = this;
        
        axios.post(this.$backendDoServidor, dataToSend)
          .then(response => {
            console.log(response.data);
            if (response.data === "O email já está cadastrado."){
              //vself.Alert(response.data, "Aviso", "e");
              swal( "O email já está cadastrado." ,  "" ,  "error" )
            }
            else{
              // Atribua a resposta aos dados do componente
                
              //  Gerar cache para login automático
              vself.GenerateCache(email, senha);
                
              console.log("Usuário cadastrado com sucesso!");
              //vself.Alert("Registro feito com sucesso!", "Sucesso", "s");
              swal( "Registro feito com sucesso!" ,  "" ,  "success" )
              vself.FormatarDados();
            }

          })
          .catch(error => {
            // Trate qualquer erro que possa ocorrer
            console.error('Erro ao fazer requisição:', error);
          });
      }
      else{
        //this.Alert("Credenciais inválidas!", "Atenção", "e");
        swal( "Credenciais inválidas!" ,  "" ,  "error" )
      }

    },

    LoginUsuario(email, senha){
      console.log("Teste login no banco de dados.");

      //  Utilizar hash do tipo SHA-256 para guardar a senha no banco de dados.
      const securePassword = this.Hash(senha);
      // if (this.isSHA256Hash(senha)){
      //   securePassword = senha;
      // }
      
      const dataToSend = {
        "opcode": 2,
        "email": email
      };

      let vself = this;
      
        axios.post(this.$backendDoServidor, dataToSend)
          .then(response => {

            if (response.data === "Email não cadastrado!"){
              //vself.Alert(response.data, "Aviso", "e");
              swal( "Email não cadastrado" ,  "" ,  "error" )
            }
            else{
              const senha_resposta = response.data[0]["senha"];
              
              console.log(response.data);
              // console.log(senha_resposta);
              console.log(securePassword);
              if (securePassword === senha_resposta){
                const dataToSend2 = {
                  "opcode": 5,
                  "email": email
                }
                
                axios.post(this.$backendDoServidor, dataToSend2)
                  .then(response =>{
                    console.log(response.data[0].cadastro_finalizado);
                    const cadastro_finalizado = response.data[0].cadastro_finalizado;
                    if (cadastro_finalizado === "0"){
                      //  Redirecionar para a página de cadastro se necessário.
                      vself.$router.push({name: "editarperfil", params: {email: email}});
                      // vself.$router.push("/editarperfil");
                      swal("Termine de preencher o perfil");
                    }
                    else {
                      //  Redirecionar para a página de cadastro se necessário.
                      vself.$router.push("/home");
                    }
                  })
                  .catch(error =>{
                    console.log("Erro ao fazer a requisição: ", error);
                  })

                //  Gerar cache para login automático
                vself.GenerateCache(email, senha);
                //vself.Alert("Login realizado!", "Bem-vindo!", "s");
                // swal( "Login realizado!" ,  "" ,  "success" )
                // console.log("loign realizado");
                vself.FormatarDados();
              }
              else{
                // vself.Alert("Email ou senha inválidos!", "Erro!", "e");
                swal("Email ou senha inválidos!", "", "error");
                // console.log("Email ou senha inválidos!");
              }
            }

          })
          .catch(error => {
            // Trate qualquer erro que possa ocorrer
            console.error('Erro ao fazer requisição:', error);
          });
    },

    //  Ao ser cadastrado, o usuário gerará registros de cache
    GenerateCache(email, senha){
      localStorage.clear();
      localStorage.setItem("session-email", email);
      localStorage.setItem("session-password", senha);
    },

  },

  //  Funções que executam assim que a aplicação
  //  for carregada e inicializada.
  mounted: function(){
    //this.Alert('Bem Vindo!', 'Notificação', 's');
    // swal( "Bem Vindo!" ,  "" ,  "success" );

  },

  //  Manipulação de dados que serão alterados
  //  durante a execução e interação com o programa.
  computed:{
  }
};

</script>

<style>
*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.fade-in {
  opacity: 0; /* Inicialmente, oculta o conteúdo */
  animation: fadeInAnimation 1s ease-in forwards; /* Animação de fade-in */
}
@keyframes fadeInAnimation {
  from {
    opacity: 0; /* Começa completamente transparente */
  }
  to {
    opacity: 1; /* Torna-se totalmente visível */
  }
}

:root {
    --azul-1: #183E9F;
    --azul-2: #1B9CE4;
}
.container-login {
    height: 100vh;
    width: 100vw;
    background-color: rgb(228, 228, 228);
    padding: 20px;
}
.logo-header{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
}
.apresentacao{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.apresentacao h1{
    font-size: 25px
}
.apresentacao p{
    font-size: 15px;
}
.form-email {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.form-email input {
    border: 2px solid rgb(206, 206, 206);
    height: 45px;
    padding-inline: 10px;
    border-radius: 10px;
    transition: all 0.1s ease-in-out;
}
.form-email input:focus {
    border: 2px var(--azul-1) solid; 
    outline: none;
}
.form-senha {
    display: flex;
    flex-direction: column;
}
.form-senha input {
    height: 45px;
    border: 2px solid rgb(206, 206, 206);
    padding-inline: 10px;
    border-radius: 10px;
    transition: all 0.1s ease-in-out;
}
.form-senha input:focus {
    border: 2px var(--azul-1) solid; 
    outline: none;
}
.form-recoverpass {
    display: flex;
    float: right
}
.form-recoverpass a {
    margin-top: 6px;
    text-decoration: none;
    font-size: 14.5px;
    color: var(--azul-1);
    font-weight: 500
}
.btn-submit {
    width: 100%;
    height: 45px;
    margin-top: 20px;
    border: none;
    color: rgb(255, 255, 255);
    font-weight: 500;
    background-color: var(--azul-1);
    border-radius: 10px;
}

.footer-loginGoogle{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.footer-loginGoogle span{

    margin-block: 10px;
}
.footer-loginGoogle button{
    width: 100%;
    height: 45px;
    font-weight: 500;
    border-radius: 10px;
    border: 2px solid rgb(206, 206, 206);
    background-color: rgb(255, 255, 255);
}

.footer-registrar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
}
.footer-registrar a {
    font-weight: 500;
    text-decoration: none;
    color: var(--azul-1)
}

</style>