<template>
    <div id="content" class="fade-in">

      <div id="container">

        
        <div id="assuntoDiv" class="row">
          <div class="col">
            <!-- <label for="assunto">Assunto</label> -->
            <input id="assunto" type="text" class="form-control" placeholder="Assunto" v-model="assunto">
          </div>
        </div>
        
        <div class="row">
          <div class="col">
            <div class="slider">
              <img v-if="imagens[0]" :src="imagens[0]" style="height: 200px; width: 250px; border-radius: 10px;" alt="Imagem 1" class="fade-in">
              <img v-if="imagens[1]" :src="imagens[1]" style="height: 200px; width: 250px; border-radius: 10px;" alt="Imagem 2" class="fade-in">
              <img v-if="imagens[2]" :src="imagens[2]" style="height: 200px; width: 250px; border-radius: 10px;" alt="Imagem 3" class="fade-in">
              <img v-if="imagens[3]" :src="imagens[3]" style="height: 200px; width: 250px; border-radius: 10px;" alt="Imagem 4" class="fade-in">
              <img v-if="imagens[4]" :src="imagens[4]" style="height: 200px; width: 250px; border-radius: 10px;" alt="Imagem 5" class="fade-in">
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col">
            <!-- <label for="texto">Descrição</label> -->
            <textarea id="texto" name="texto" rows="5" cols="29" v-model="descricao" placeholder="Descrição da postagem" @input="updateChar()"></textarea><br>
            <p>Caracteres restantes: {{ chars }}</p>
          </div>
        </div>

        <div id="postButton" class="row">
          <div class="col">
            <input id="uploadImage" style="display: none;" type="file" @change="handleFileInputChange">
            <button class="btn btn-primary" @click="AdicionarImagem()">Imagem <i id="postarIcon" class="bi bi-image"></i></button>
          </div>

          <div class="col">
            <button id="postarButton" class="btn btn-success" @click="Postar()">Postar <i class="bi bi-send-fill"></i></button>
          </div>
        </div>
        
      </div>

      <div id="newNavBar">
          <router-link to="/home" class="nav-link"><i class="bi bi-house-fill navicon"></i></router-link>
          <router-link to="/meuschats" class="nav-link"><i class="bi bi-chat-fill navicon"></i></router-link>
          <router-link to="/postar" class="nav-link"><i class="bi bi-plus-square-fill navicon"></i></router-link>
          <router-link to="/perfil" class="nav-link"><i class="bi bi-person-fill navicon"></i></router-link>
          <router-link to="/sobre" class="nav-link"><i class="bi bi-info-square-fill navicon"></i></router-link>
      </div>
  
    </div>
  </template>
  
<script>
  import axios from 'axios';
  import swal from 'sweetalert';
  import utils from '../utils';
  import ImageConversion from "image-conversion";
  
  export default {
      name: "home",
  
      data: function() {
        return{
            emailCache: localStorage.getItem("session-email"),
            imagens: [],
            assunto: "",
            descricao: "",
            altura: 0,
            largura: 0,
            chars: 200
        }
      },
  
      methods: {
        Sair(){
          console.log("Cache limpo");
          localStorage.clear();
          this.$router.push("/");
        },

        //Teste com compress Image
        CompressImage(imageBase64, qualidade){
          console.log("CompressImage chamado com:", imageBase64, qualidade);
          const image = ImageConversion.fromBase64(imageBase64);
          const newImage = image.quality(qualidade);
          const newImageBase64 = newImage.toBase64();
          // console.log("Comprimindo imagem...");
          return newImageBase64;
        },

        handleFileInputChange(event) {
          const file = event.target.files[0];
          const reader = new FileReader();
          reader.onload = async () => {
            // Quando o arquivo é carregado, converte o conteúdo em Base64
            // this.imagens = reader.result;
            if (this.imagens.length >= 5){
              swal("Limite de 5 imagens por postagem!", "", "error");
            }
            else{
              this.imagens.push(reader.result);
              // const imagemComprimida = await this.CompressImage(reader.result, 0.6);
              // this.imagens.push(imagemComprimida);
              console.log(this.imagens.length);
            }
          };
          // Lê o conteúdo do arquivo como URL de dados
          reader.readAsDataURL(file);
        },

        AdicionarImagem(){
          document.getElementById('uploadImage').click();
        },


        Postar(){
            if(this.descricao,length <= 200){

                // for (let i = 0; i < this.images.length; i++){
                //   this.imagens[i] = this.CompressImage(this.imagens[i], 0.6);
                // }

                let dataToSend = {
                    "opcode": 9,
                    "email": this.emailCache,
                    "imagens": this.imagens,
                    "assunto": this.assunto,
                    "descricao": this.descricao
                }
                
                this.$router.push("/home");
                
                let vself = this;
                axios.post(this.$backendDoServidor, dataToSend)
                .then(response =>{
                        // console.log(response);
                        console.log("Postagem feita!");
                        // vself.$router.push("/home");
                        // console.log(response.data);
                        // this.descricao = "";
                        // swal("Postagem feita com sucesso!", "", "success");
                    })
                    .catch(err =>{
                        console.log("Erro:", err);
                        swal("Erro de postagem!", "", "error");
                    })
            }

        },

        updateChar(){
            this.chars = 200 - this.descricao.length;
            console.log(this.chars);
        }
      },
  
      mounted: function(){
        utils.IsLogged();
      },

      computed:{
      }
  
      
  };
</script>
  
<style scoped>
*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.fade-in {
  opacity: 0; /* Inicialmente, oculta o conteúdo */
  animation: fadeInAnimation 0.5s ease-in forwards; /* Animação de fade-in */
}
@keyframes fadeInAnimation {
  from {
    opacity: 0; /* Começa completamente transparente */
  }
  to {
    opacity: 1; /* Torna-se totalmente visível */
  }
}

#content{
  background-color: rgb(228, 228, 228);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40vh;
}

#newNavBar{
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-around; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  /* background: rgb(167,167,167); */
  background: rgb(225, 225, 225);
  /* background: linear-gradient(0deg, rgba(225,225,225,1) 0%, rgba(255,255,255,1) 100%);   */
  height: 2.5rem;
  border: none;
  box-shadow: 0px 0px 5px;
}
.bi{
  /* text-shadow: 0px 0px 100px black; */
  cursor: pointer;
  /* font-size: 1.5rem;  */
}
.navicon {
  font-size: 1.5rem; /* Altere o valor conforme desejado */
  color: rgb(160, 160, 160);
  text-shadow: none;
  margin: 5px;
}
.bi-plus-square-fill{
  /* color: rgb(175,161,14); */
  /* color: rgb(50, 50, 50); */
  color: var(--azul-1);
  border-bottom: 3px solid var(--azul-1);
}
#postarIcon{
  color: white;
}

.form-control{
  box-shadow: none;
}

.form-control:focus{
    border: 2px var(--azul-1) solid; 
    outline: none;
}

input, textarea{
    margin-top: 0.5rem;
    border: 2px solid rgb(206, 206, 206);
    padding-inline: 10px;
    border-radius: 10px;
    transition: all 0.1s ease-in-out;
}
input:focus, textarea:focus{
    border: 2px var(--azul-1) solid; 
    outline: none;
}

#postButtons{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* #postarButton{
  background: rgb(37,157,29);
  background: linear-gradient(0deg, rgba(37,157,29,1) 0%, rgba(113,166,94,1) 100%);
} */

  /* #container{
    margin-top: 5rem;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

#container{
  padding-top: 5rem;
  /* padding-bottom: 15rem; */
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  
  max-width: 20rem;
}
  

.slider{
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 20rem;
  max-height: 20rem;
  /* box-shadow: 1px 1px 1px black; */
  border: none;
}

#texto{
    margin-top: 0.5rem;
    text-align: justify;
    border-radius: 10px;
    /* border: solid black 1px; */
    /* border: none; */
}

#assunto{
    margin-top: 2rem;
    border-radius: 10px;
    /* border: solid black 1px; */
}

#assuntoDiv{
  margin-bottom: 1rem;
}
</style>