<template>
</template>
  
<script>
  import axios from "axios";
  import { error } from "jquery";
  import swal from 'sweetalert';
  import utils from '../utils';
  
  export default {
    name: "init",
  
    //  Armazenamento de variáveis e valores.
    data: function(){
      return{
        email: "",
        senha: ""
      }
    },
  
    /*
      Definição de funções que serão utilizadas
      de acordo com a necessidade.
    */
    methods: {
      //  Função personalizada para exibição de alertas.
      Alert(msg, title, type){
        //this.$toastr.s(msg, title);
        if (type === "s"){
          this.$toastr.s(msg, title);
        }
        else if (type === "w"){
          this.$toastr.w(msg, title);
        }
        else if (type === "e"){
          this.$toastr.e(msg, title);
        }
      },
  
      //  Função/Método para formatar/limpar os dados.
      FormatarDados(){
        this.email = ""
        this.senha = ""
      },
  
      LoginUsuario(email, senha){
        console.log("Teste login no banco de dados.");
  
        //  Utilizar hash do tipo SHA-256 para guardar a senha no banco de dados.
        const securePassword = utils.Hash(senha);
        const dataToSend = {
          "opcode": 2,
          "email": email
        };
  
        let vself = this;
        
          axios.post(this.$backendDoServidor, dataToSend)
            .then(response => {
  
              if (response.data === "Email não cadastrado!"){
                //vself.Alert(response.data, "Aviso", "e");
                swal( "Email não cadastrado!" ,  "" ,  "error" )
              }
              else{
                const senha_resposta = response.data[0]["senha"];
      
                console.log(senha_resposta);
                console.log(securePassword);
                if (securePassword === senha_resposta){
                  const dataToSend2 = {
                    "opcode": 5,
                    "email": email
                  }
                  axios.post(this.$backendDoServidor, dataToSend2)
                    .then(response =>{
                      console.log(response.data[0].cadastro_finalizado);
                      const cadastro_finalizado = response.data[0].cadastro_finalizado;
                      if (cadastro_finalizado === "0"){
                        //  Redirecionar para a página de cadastro se necessário.
                        // vself.$router.push({name: "cadastro", params: {email: email}});
                        vself.$router.push("/editarperfil");
                      }
                      else {
                        //  Redirecionar para a página de cadastro se necessário.
                        vself.$router.push("/home");
                      }
                    })
                    .catch(error =>{
                      console.log("Erro ao fazer a requisição: ", error);
                    })
  
                  // vself.Alert("Login realizado!", "Bem-vindo!", "s");
                  // swal("");
                  // console.log("loign realizado");
                  vself.FormatarDados();
                }
                else{
                  // vself.Alert("Email ou senha inválidos!", "Erro!", "e");
                  swal("Email ou senha inválidos!", "", "error");
                  // console.log("Email ou senha inválidos!");
                }
              }
  
            })
            .catch(error => {
              // Trate qualquer erro que possa ocorrer
              console.error('Erro ao fazer requisição:', error);
            });
      },
  
      //  Tentativa de login caso já haja algum dado salvo em cache
      LoginCache(){
        let email = localStorage.getItem("session-email");
        let password = localStorage.getItem("session-password");
      
        console.log(email);

        try{
          if (email.length > 0){
            this.LoginUsuario(email, password);
          }
        }
        catch{
          console.log("Não há dados em cache");
          // this.$router.push("/login");
          this.$router.push("/loginnovo");
        }
      }
  
    },
  
    //  Funções que executam assim que a aplicação
    //  for carregada e inicializada.
    mounted: function(){
      this.LoginCache();
    },
  
    //  Manipulação de dados que serão alterados
    //  durante a execução e interação com o programa.
    computed:{
    }
  };
  
</script>
  
<style scoped>
</style>