<template>

</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
import utils from '../utils';

export default{
    name: "initchat",

    data: function(){
        return{
            emailToVerify: localStorage.getItem("session-email"),
            idpost: this.$route.params.idpost
        }
    },

    methods:{
        VerificarUsuarioDono(){

            const dataToSend = {
                "opcode": 10,
                "email": this.emailToVerify,
                "idpost": this.idpost
            }

            axios.post(this.$backendDoServidor, dataToSend)
                .then(response =>{
                    // console.log(response);
                    const info = response.data;
                    // console.log(this.idpost);
                    // console.log(info);

                    if (info === "O usuário não é dono da postagem"){
                        this.$router.push({name: "chat", params: {idpost: this.idpost}});
                    }
                    else{
                        this.$router.push("/home");
                        swal("Você não pode interagir com você mesmo", "", "error");
                    }
                })
                .catch(err =>{
                    console.error("Erro ao iniciar chat: ", err)
                })
        }
    },

    mounted: function(){
        utils.IsLogged();
        this.VerificarUsuarioDono();
    },

    computed:{
    }
}
</script>

<style scoped>
</style>