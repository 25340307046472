import axios from 'axios';
import ms from "ms";

export default {
    HelloWorld(){
        console.log("Olá, Mundo!");
        console.log("Importação bem sucedida de métodos globais!");
    },

    //  Função para gerar hash, que no caso
    //  utiliza o MD5 para gerar o hash.
    Hash(content){
        const CryptoJS = require("crypto-js");
        const hash = CryptoJS.SHA256(content);
        console.log("Hash sendo usado");
        return hash.toString(CryptoJS.enc.Hex);
    },

    IsLogged(){
        const email = localStorage.getItem("session-email");
        if (email === null){
            window.location.href = "/loginnovo";
            
        }
    },

    // convertTimeToHoursMinutes(seconds) {
    //     // Calculate minutes
    //     const minutes = Math.floor(seconds / 60);
      
    //     // Calculate remaining seconds
    //     const remainingSeconds = seconds % 60;
      
    //     // Format minutes with leading zeros
    //     const formattedMinutes = (minutes < 10) ? 0${minutes} : minutes;
      
    //     // Calculate hours
    //     const hours = Math.floor(minutes / 60);
      
    //     // Format hours with leading zeros
    //     const formattedHours = (hours < 10) ? 0${hours} : hours;
      
    //     // Return formatted time
    //     return ${formattedHours}:${formattedMinutes}s;
    // },

    TempoDePost(value){
        const data = new Date();
        const ano = data.getFullYear() * 31104000;
        const mes = (data.getMonth()+1) * 2592000;
        const dia = data.getDate() * 86400;
        const hora = data.getHours() * 3600;
        const minutos = data.getMinutes() * 60;
        const segundos = data.getSeconds();
        const dataAtual = ano+mes+dia+hora+minutos+segundos;
        const dataServidor = value;
        const result = dataAtual - dataServidor;
        
        let tempoFormatado = ms(result * 1000, { long: true });
        
        tempoFormatado = tempoFormatado.replace("second", "segundo");
        tempoFormatado = tempoFormatado.replace("seconds", "segundos");
        tempoFormatado = tempoFormatado.replace("minute", "minuto");
        tempoFormatado = tempoFormatado.replace("minutes", "minutos");
        tempoFormatado = tempoFormatado.replace("hour", "hora");
        tempoFormatado = tempoFormatado.replace("hours", "horas");
        tempoFormatado = tempoFormatado.replace("day", "dia");
        tempoFormatado = tempoFormatado.replace("days", "dias");
        tempoFormatado = tempoFormatado.replace("week", "semana");
        tempoFormatado = tempoFormatado.replace("weeks", "semanas");
        tempoFormatado = tempoFormatado.replace("mounth", "mês");
        tempoFormatado = tempoFormatado.replace("mounths", "mêses");
        tempoFormatado = tempoFormatado.replace("year", "ano");
        tempoFormatado = tempoFormatado.replace("years", "anos");
        
        return tempoFormatado;
        // if (result < 60){
        //     return(parseInt(result)+" segundos");
        // }
        // else if (result < 3600){
        //     return(parseInt(result/60)+" minutos");
        // }
        // else if (result < 216000){
        //     return(parseInt(result/3600)+" horas");
        // }
        // else if (result < 12960000){
        //     return(parseInt(result/216000)+" dias");
        // }
        // else if (result < 777600000){
        //     return(parseInt(result/12960000)+" meses");
        // }
        // else if (result < 46656000000){
        //     return(parseInt(result/777600000)+" anos");
        // }
    },
    
}