<template>
  <div class="fade-in">
    
    <!-- Criação do Home do aplicativo "App Solidário". -->
    <div id="loginPage" class="container text-white">
      
      <!-- 
        Todo o conteúdo ficará e será exibido
        em uma única coluna para se adequar ao 
        modelo de aplicação mobile.  
      -->
      
      <div id="fundo_de_login">
          <div class="row">
            <div class="col">
              <h1 id="title">App Solidário</h1>
            </div>
          </div>
          
          <div class="row">
            <div class="col">
              <p>
                Seja bem-vindo! <br> 
                Faça login para acessar sua conta <br>
                ou registre-se para criar uma.
              </p>
            </div>
          </div>
  
          <div id="loginInputs" class="row">
            <!-- Grupos de input para email e senha. -->
            <div class="col-md-12">
              <div class="input-group">
                <input type="email" class="form-control" placeholder="email" v-model="email" required>
              </div>
            </div>
          </div>


          <div id="loginInputs" class="row">
            <!-- Grupos de input para email e senha. -->
            <div class="col-md-12">
              <div class="input-group">
                <input type="password" class="form-control" placeholder="senha" v-model="senha" required>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Tentativa de implementação da função de "esqueci minha senha" -->
            <div class="col">
                <a id="esqueciMinhaSenha" href="LoginRecover">Esqueci minha senha</a>
            </div>
          </div>
          
          <div class="col">
            <!-- Botões para registrar e fazer o login. -->
            <div class="row" style="margin-top: 2%; padding: 10px;">
              <button id="registrarButton" type="button" class="btn btn-success" @click="RegistrarUsuario(email, senha)">Registrar <i class="bi bi-box-arrow-in-right"></i></button>
              <button id="loginButton" type="button" class="btn btn-primary" @click="LoginUsuario(email, senha)">Login <i class="bi bi-door-open-fill"></i></button>
            </div>
          </div>

        </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import { error } from "jquery";
import swal from 'sweetalert';

export default {
  name: "login",

  //  Armazenamento de variáveis e valores.
  data: function(){
    return{
      email: "",
      senha: ""
    }
  },

  /*
    Definição de funções que serão utilizadas
    de acordo com a necessidade.
  */
  methods: {
    isSHA256Hash(text) {
      // Verifica o comprimento do texto
      if (text.length !== 64) {
          return false;
      }
      // Verifica se todos os caracteres são hexadecimais
      if (!/^[a-fA-F0-9]*$/.test(text)) {
          return false;
      }
      return true;
    },

    Random(number1, number2){
      return Math.floor(Math.random() * number2) + number1;
    },

    //  Função para gerar hash, que no caso
    //  utiliza o MD5 para gerar o hash.
    Hash(content){
      const CryptoJS = require("crypto-js");
      const hash = CryptoJS.SHA256(content);
      return hash.toString(CryptoJS.enc.Hex);
    },

    //  Função personalizada para exibição de alertas.
    Alert(msg, title, type){
      //this.$toastr.s(msg, title);
      if (type === "s"){
        this.$toastr.s(msg, title);
      }
      else if (type === "w"){
        this.$toastr.w(msg, title);
      }
      else if (type === "e"){
        this.$toastr.e(msg, title);
      }
    },

    //  Função de teste.
    HelloWorld(){
      this.Alert('Registro processado com sucesso!', 'Informação!');
    },

    //  Função/Método para formatar/limpar os dados.
    FormatarDados(){
      this.email = ""
      this.senha = ""
    },

    //  Implementação da função de registrar no banco de dados com php.
    RegistrarUsuario(email, senha){
      console.log("Teste registro no banco de dados.");
      
      //  Utilização de uma expressão regular para validar o formato do email.
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let emailValido = regex.test(email);

      if (emailValido === true && senha.length >= 8){
        //  Utilizar hash do tipo SHA-256 para guardar a senha no banco de dados.
        const securePassword = this.Hash(senha);
        const dataToSend = {
          "opcode": 1,
          "email": email,
          "senha": securePassword,
        };
  
        let vself = this;
        
        axios.post(this.$backendDoServidor, dataToSend)
          .then(response => {
            console.log(response.data);
            if (response.data === "O email já está cadastrado."){
              //vself.Alert(response.data, "Aviso", "e");
              swal( "O email já está cadastrado." ,  "" ,  "error" )
            }
            else{
              // Atribua a resposta aos dados do componente
                
              //  Gerar cache para login automático
              vself.GenerateCache(email, senha);
                
              console.log("Usuário cadastrado com sucesso!");
              //vself.Alert("Registro feito com sucesso!", "Sucesso", "s");
              swal( "Registro feito com sucesso!" ,  "" ,  "success" )
              vself.FormatarDados();
            }

          })
          .catch(error => {
            // Trate qualquer erro que possa ocorrer
            console.error('Erro ao fazer requisição:', error);
          });
      }
      else{
        //this.Alert("Credenciais inválidas!", "Atenção", "e");
        swal( "Credenciais inválidas!" ,  "" ,  "error" )
      }

    },

    LoginUsuario(email, senha){
      console.log("Teste login no banco de dados.");

      //  Utilizar hash do tipo SHA-256 para guardar a senha no banco de dados.
      const securePassword = this.Hash(senha);
      // if (this.isSHA256Hash(senha)){
      //   securePassword = senha;
      // }
      
      const dataToSend = {
        "opcode": 2,
        "email": email
      };

      let vself = this;
      
        axios.post(this.$backendDoServidor, dataToSend)
          .then(response => {

            if (response.data === "Email não cadastrado!"){
              //vself.Alert(response.data, "Aviso", "e");
              swal( "Email não cadastrado" ,  "" ,  "error" )
            }
            else{
              const senha_resposta = response.data[0]["senha"];
    
              console.log(senha_resposta);
              console.log(securePassword);
              if (securePassword === senha_resposta){
                const dataToSend2 = {
                  "opcode": 5,
                  "email": email
                }
                
                axios.post(this.$backendDoServidor, dataToSend2)
                  .then(response =>{
                    console.log(response.data[0].cadastro_finalizado);
                    const cadastro_finalizado = response.data[0].cadastro_finalizado;
                    if (cadastro_finalizado === "0"){
                      //  Redirecionar para a página de cadastro se necessário.
                      vself.$router.push({name: "editarperfil", params: {email: email}});
                      // vself.$router.push("/editarperfil");
                      swal("Termine de preencher o perfil");
                    }
                    else {
                      //  Redirecionar para a página de cadastro se necessário.
                      vself.$router.push("/home");
                    }
                  })
                  .catch(error =>{
                    console.log("Erro ao fazer a requisição: ", error);
                  })

                //  Gerar cache para login automático
                vself.GenerateCache(email, senha);
                //vself.Alert("Login realizado!", "Bem-vindo!", "s");
                swal( "Login realizado!" ,  "" ,  "success" )
                // console.log("loign realizado");
                vself.FormatarDados();
              }
              else{
                // vself.Alert("Email ou senha inválidos!", "Erro!", "e");
                swal("Email ou senha inválidos!", "", "error");
                // console.log("Email ou senha inválidos!");
              }
            }

          })
          .catch(error => {
            // Trate qualquer erro que possa ocorrer
            console.error('Erro ao fazer requisição:', error);
          });
    },

    //  Ao ser cadastrado, o usuário gerará registros de cache
    GenerateCache(email, senha){
      localStorage.clear();
      localStorage.setItem("session-email", email);
      localStorage.setItem("session-password", senha);
    },

  },

  //  Funções que executam assim que a aplicação
  //  for carregada e inicializada.
  mounted: function(){
    //this.Alert('Bem Vindo!', 'Notificação', 's');
    swal( "Bem Vindo!" ,  "" ,  "success" )

  },

  //  Manipulação de dados que serão alterados
  //  durante a execução e interação com o programa.
  computed:{
  }
};

</script>

<style scoped>
*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.fade-in {
  opacity: 0; /* Inicialmente, oculta o conteúdo */
  animation: fadeInAnimation 0.5s ease-in forwards; /* Animação de fade-in */
}
@keyframes fadeInAnimation {
  from {
    opacity: 0; /* Começa completamente transparente */
  }
  to {
    opacity: 1; /* Torna-se totalmente visível */
  }
}

.bi {
  font-size: 1.5rem; /* Altere o valor conforme desejado */
  color: white;
}
input{
  margin-top: 0.5rem;
  color: black;
  box-shadow: 1px 1px 1px black;
  background: rgb(167,167,167);
  background: linear-gradient(0deg, rgba(167,167,167,1) 0%, rgba(255,255,255,1) 100%); 
}
button{
  margin-top: 0.5rem;
  box-shadow: 1px 1px 1px black;
  text-shadow: 1px 1px 1px black;
  background: rgb(31,105,158);
  background: linear-gradient(0deg, rgba(31,105,158,1) 0%, rgba(111,179,227,1) 100%);
}
#registrarButton{
  background: rgb(37,157,29);
  background: linear-gradient(0deg, rgba(37,157,29,1) 0%, rgba(113,166,94,1) 100%);
}
#loginButton{
  background: rgb(21,74,156);
  background: linear-gradient(0deg, rgba(21,74,156,1) 0%, rgba(31,112,182,1) 100%); 
}

#esqueciMinhaSenha{
  color: white;
}

#loginPage{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 5rem;
  max-width: 19rem;
  border-radius: 10px;
  padding: 10px;
  background: rgb(31,105,158);
  background: linear-gradient(0deg, rgba(31,105,158,1) 0%, rgba(111,179,227,1) 100%);
  justify-content: space-between;
  box-shadow: 1px 1px 1px black;
}

.form-control{
  text-align: center;
}

</style>