<template>
    <div class="conatiner fade-in">      

      <div id="usuarioPerfil" class="col bg-dark text-white">
          <!-- <a class="nav-link" href="/meuschats"><i class="bi bi-box-arrow-left"></i></a> -->
          <router-link to="/meuschats" class="nav-link"><i class="bi bi-box-arrow-left"></i></router-link>
          <img id="foto_perfil" v-if="foto_perfil" :src="this.$imagesDoServidor + foto_perfil" alt="Foto de perfil" accept="image/*">
          <h2 id="nome">{{ nome }}</h2>
      </div>


        <div id=content class="col">
            <div id="messages" class="col">
              <div v-for="(message, index) in messagesToLoad" :key="index">
                  <div id="msg" :class="getClass(message[1])">
                    <h2>{{ message[0] }}</h2>            
                    <h6 id="envio">{{ message[2] }}</h6>
                    <h6 id="lido" v-if="message[3] == 0 && message[1] == msgfrom"><i class="bi bi-check"></i></h6>
                    <h6 id="lido" v-if="message[3] == 1 && message[1] == msgfrom"><i class="bi bi-check-all"></i></h6>
                  </div>
              </div>
            </div>

            
            
          </div>
          
          <div id="enviarContent">
            <div class="input-group col">
                <input id="sendInput" type="text" class="form-control" placeholder="digite sua mensagem" v-model="messageToSend">
                <button id="sendButton" class="btn btn-success" @click="Enviar()"><i class="bi bi-send-fill"></i></button>
            </div>
          </div>
    </div>


</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
import utils from '../utils';

export default{
    name: "chat",

    data: function(){
        return{
            idpost: this.$route.params.idpost,
            messagesToLoad: [],
			      msgfrom: "",
            messageToSend: "",
            firstLoad: 0,
            foto_perfil: "",
            nome: "",
            timeoutId: null // Adiciona uma variável para armazenar o ID do timeout
        }
    },

    methods: {
        Sair(){
          console.log("Cache limpo");
          localStorage.clear();
          this.$router.push("/");
        },

        getClass(value){
          if (parseInt(value) === this.msgfrom){
            return "minhasMensagens";
          }
          else{
            return "mensagensOutro";
          }
        },

        DescobrirId(){
        	const dataToSend = {
            	"opcode": 13,
            	"email": localStorage.getItem("session-email")
          	}
            axios.post(this.$backendDoServidor, dataToSend)
              .then(response =>{
              //   console.log(response.data);
              this.msgfrom = response.data;
                console.log(this.msgfrom);
              })
              .catch(err =>{
                console.error("Erro ao decobrir id: ", err);
              })
        },
        
        //  Função que carrega as mensagens
        CarregarMensagens(){
			      // console.log("procurando mensagens...");
            // console.log("Carregando mensagens...");

            const dataToSend = {
                "opcode": 12,
                "idpost": this.idpost,
				        "msgfrom": this.msgfrom
            }

            axios.post(this.$backendDoServidor, dataToSend)
              .then(response =>{
            	  	// console.log(response.data);
				          this.messagesToLoad = [];
                  for (let i = 0; i < response.data.length; i++){
                        this.messagesToLoad.push(response.data[i])
                  }
              })
              .catch(err =>{
                console.error("Erro ao carregar as mensagens: ", err);
              })

              if(this.firstLoad < 2){
                this.scrollToBottom();
                this.firstLoad += 1;
              }

              this.CarregarFotoPerfil();

              //	Tempo de espera até chamar a função de carregar as mensagens novamente
              // setTimeout(this.CarregarMensagens, 1000);
              this.timeoutId = setTimeout(this.CarregarMensagens, 1000);
        },

        Enviar(){
            const dataToSend = {
                "opcode": 11,
                "idpost": this.idpost,
                "mensagem": this.messageToSend,
                "email": localStorage.getItem("session-email"),
				        "msgfrom": this.msgfrom
            }
            
            axios.post(this.$backendDoServidor, dataToSend)
            .then(response =>{
                    const info =  response.data;
                    // console.log(info);
                    // console.log(this.idpost);
                    console.log("Enviando mensagem...");

                })
            .catch(err =>{
                console.log("Erro ao enviar a mensagem: ", err);
            })

            this.messageToSend = "";
            this.scrollToBottom();
        },

        scrollToBottom() {
          window.scrollTo(0, document.body.scrollHeight);
        },

        CarregarFotoPerfil(){
          // console.log("Foto de perfil do usuário carregada");

          let dataToSend = {
            "opcode": 17,
            "instancia": 0,
            "email": localStorage.getItem("session-email"),
            "idpost": this.idpost
          }

          axios.post(this.$backendDoServidor, dataToSend)
            .then(response =>{
              console.log(response.data);
              this.foto_perfil = response.data[0][0];
              this.nome = response.data[0][1]
            })
            .catch(err =>{
              console.error("Erro ao carregar a foto de perfil do usuário: ", err);
            })
        }
    },

    beforeDestroy() {
      // Limpa o timeout quando o componente é desmontado para evitar vazamentos de memória
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
    },

    mounted: function(){
      utils.IsLogged();
		  this.DescobrirId();
    	this.CarregarMensagens();
    },

    computed: {
    }
}
</script>

<style scoped>
*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.fade-in {
  opacity: 0; /* Inicialmente, oculta o conteúdo */
  animation: fadeInAnimation 0.5s ease-in forwards; /* Animação de fade-in */
}
@keyframes fadeInAnimation {
  from {
    opacity: 0; /* Começa completamente transparente */
  }
  to {
    opacity: 1; /* Torna-se totalmente visível */
  }
}

.container{
  margin: auto;
}

#content{
	display: flex;
  flex-direction: column;
  margin-left: 1rem;
	/* align-items: center; */
	/* justify-content: center; */
}

#usuarioPerfil{
  margin-top: 0rem;
  z-index: 1;
  position: fixed;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-between; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  width: 100%;
  padding: 2.5px;
  background: rgb(0,0,0);
  /* background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(40,40,40,1) 100%); */
  overflow: hidden;
  border: none;
}
.bi {
  font-size: 1.5rem; /* Altere o valor conforme desejado */
  color: white;
  text-shadow: 1px 1px 1px black;
}
.bi-chat-fill{
  color: rgb(175,161,14);
}

input{
  color: black;
  background: rgb(167,167,167);
  background: linear-gradient(0deg, rgba(167,167,167,1) 0%, rgba(255,255,255,1) 100%); 
}

#foto_perfil{
  width: 35px;
  height: 35px;
  border-radius: 50%; /* Torna a imagem redonda */
  object-fit: cover; /* Garante que a imagem cubra todo o espaço designado */
  display: flex;
  margin: auto; /* Centraliza horizontalmente */
  margin-left: 1rem;
}

#nome{
  margin-right: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#chatContent{
    margin-bottom: 100px;
}

#enviarContent{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
	  position: fixed;
	  bottom: 0;
    width: 100%;
}

#messages{
    margin-top: 3rem;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    width: 95%;
}

#envio{
  margin: 10px;
}

#msg{
    color: white;
    border-radius: 18px;
    padding: 0.6rem;
    max-width: 200px !important;
    margin: 5px; /* Adiciona um espaçamento entre as mensagens */
    clear: both; /* Limpa o float */
}

.minhasMensagens{
    /* background-color: rgb(91, 186, 91) !important; */
    float: right; /* Alinha as mensagens do usuário à direita */
    background: rgb(31,105,158);
    /* background: linear-gradient(0deg, rgba(31,105,158,1) 0%, rgba(111,179,227,1) 100%); */
    border-bottom-right-radius: 0px !important;
}
.mensagensOutro{
    /* background-color: rgb(145, 145, 145) !important; */
    float: left; /* Alinha as mensagens de outros usuários à esquerda */
    background: rgb(73,73,73);
    /* background: linear-gradient(0deg, rgba(73,73,73,1) 0%, rgba(156,156,156,1) 100%);  */
    border-bottom-left-radius: 0px !important;
}

#sendInput{
  height: 50px; 
}
</style>