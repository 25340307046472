<template>
    <div id="content" class="fade-in">
    
      <div id="topNavBar">
        <h3 id="appTitle">App Solidário</h3>
          
        <div id="editarArea">
          <i class="bi bi-pencil-square" @click="Editar"></i>
        </div>
      </div>

        <div id="container">

            <div id="fotoNome" class="row">
              <div class="col">
                <img id="foto_perfil" v-if="foto_perfil" :src="$imagesDoServidor + foto_perfil" alt="Foto de perfil" accept="image/*">
              
                <h1 id="nomeDeUsuario">{{ nome }}</h1>
              </div>
            </div>


            <div id="informacoesDoUsuario" class="form">


              <div class="row">
                <!-- <label for="a">CEP</label> -->
                <div class="col">
                  <p id="a" class="badge bg-primary">CEP: {{ cep }}</p>
                </div>
              </div>
              
              <div class="row">
                <!-- <label for="">Logradouro</label> -->
                <div class="col">
                  <p class="badge bg-primary">Logradouro: {{ logradouro }}</p>
                </div>
              </div>

              <div class="row">
                <!-- <label for="">Bairro</label> -->
                <div class="col">
                  <p class="badge bg-primary">Bairro: {{ bairro }}</p>
                </div>
              </div>

              <div class="row">
                <!-- <label for="">UF</label> -->
                <div class="col">
                  <p class="badge bg-primary">Unidade Federativa: {{ uf }}</p>
                </div>
              </div>
                
            </div>
            

            <button class="btn btn-danger" @click="Sair()">Encerrar seção <i class="bi bi-box-arrow-left"></i></button>

            <!-- <hr>
            <button type="button" class="btn btn-primary" @click="Editar">Editar <i class="bi bi-pencil-square"></i></button> -->
            <hr>

            <h1>Minhas postagens:</h1>
            <div class="postsContainer">
              <div id="container" v-for="(post, index) in postsToView" :key="index" >
                <ul class="list-group">
                  <li id="status" class="badge bg-warning" v-show="post[13] === '0'">Em andamento</li>
                  <li id="status" class="badge bg-success" v-show="post[13] === '1'">Concluído</li>
                  
                  <!--Exibição do assunto da postagem-->
                  <li id="idAssunto" class="badge">{{ post[1] }}</li>
                  
                  <!--Foto de perfil do usuário-->
                  <li id="userInfo" class="list-group-item" style="display: flex; margin-left: 0px;"> 
                    <img id="foto_perfil2" :src="$imagesDoServidor + post[15]" style="height: 30px; width: 30px;">
                    <span id="userName" style="margin-left: 10px; overflow: hidden;">{{ post[3] }}</span>

                    <div id="actions">
                      <button class="btn btn-primary" @click="EditarSituacao(post[0])"><i class="bi bi-arrow-clockwise"></i></button>
                      <button class="btn btn-danger" @click="ExcluirPost(post[0])"><i id="excluirIcon" class="bi bi-trash-fill"></i></button>
                    </div>
                  </li>

                  <!--Slider com as imagens da postagem-->
                  <!-- <div class="slider">
                    <li v-if="post[5] !== null" class="list-group-item" style="text-align: center;"> <img :src="$imagesDoServidor + post[5]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                    <li v-if="post[6] !== null" class="list-group-item" style="text-align: center;"> <img :src="$imagesDoServidor + post[6]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                    <li v-if="post[7] !== null" class="list-group-item" style="text-align: center;"> <img :src="$imagesDoServidor + post[7]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                    <li v-if="post[8] !== null" class="list-group-item" style="text-align: center;"> <img :src="$imagesDoServidor + post[8]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                    <li v-if="post[9] !== null" class="list-group-item" style="text-align: center;"> <img :src="$imagesDoServidor + post[9]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                  </div> -->
                  <!--Slider com as imagens da postagem-->
                <div class="slider">
                  <li v-if="post[5] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[5])"> <img class="slider-img" :src="$imagesDoServidor + post[5]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                  <li v-if="post[6] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[6])"> <img class="slider-img" :src="$imagesDoServidor + post[6]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                  <li v-if="post[7] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[7])"> <img class="slider-img" :src="$imagesDoServidor + post[7]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                  <li v-if="post[8] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[8])"> <img class="slider-img" :src="$imagesDoServidor + post[8]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                  <li v-if="post[9] !== null" class="list-group-item li-img" style="text-align: center;" @click="ZoomImage($imagesDoServidor + post[9])"> <img class="slider-img" :src="$imagesDoServidor + post[9]" style="height: 200px; width: 250px; border-radius: 10px;"  alt=" "> </li>
                </div>

                  <!--Descrição da postagem-->
                  <!-- <li id="idAssunto" class="list-group-item">Assunto: {{ post[1] }}</li> -->
                  <li class="list-group-item">
                    <!-- <textarea id="idAssunto" disabled>Assunto: {{ post[1] }}</textarea> -->
                    <textarea id="descricao" disabled cols="30" rows=auto :value="post[10]"></textarea>
                  </li>
                  
                  <!--Data de publicação da postagem-->
                  <!-- <li class="list-group-item" style="text-align: left; font-size: 14px;">Publicado há: {{ Tempo(post[14]) }} </li> -->
                  <li class="list-group-item" style="text-align: left; font-size: 14px;">Publicado há: {{ Tempo(post[14]) }} </li>
                </ul>
              </div>
              
            </div>

        </div>

        <!-- <div id="newNavBar">
            <a class="nav-link" href="/home"><i class="bi bi-house-fill navicon"></i></a>
            <a class="nav-link" href="/meuschats"><i class="bi bi-chat-fill navicon"></i></a>
            <a class="nav-link" href="/postar"><i class="bi bi-plus-square-fill navicon"></i></a>  
            <a class="nav-link" href="/perfil"><i class="bi bi-person-fill navicon"></i></a>
            <a class="nav-link" href="/sobre"><i class="bi bi-info-square-fill navicon"></i></a>
        </div> -->

        <div id="newNavBar">
          <router-link to="/home" class="nav-link"><i class="bi bi-house-fill navicon"></i></router-link>
          <router-link to="/meuschats" class="nav-link"><i class="bi bi-chat-fill navicon"></i></router-link>
          <router-link to="/postar" class="nav-link"><i class="bi bi-plus-square-fill navicon"></i></router-link>
          <router-link to="/perfil" class="nav-link"><i class="bi bi-person-fill navicon"></i></router-link>
          <router-link to="/sobre" class="nav-link"><i class="bi bi-info-square-fill navicon"></i></router-link>
          <!-- <a class="nav-link" @click="Sair()"><i class="bi bi-box-arrow-left navicon"></i></a> -->
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import utils from '../utils';

export default {
  name: "perfil",

  data: function() {
    return{
      emailCache: localStorage.getItem("session-email"),
      nome: "",
      cep: "",
      logradouro: "",
      bairro: "",
      municipio: "",
      uf: "",
      foto_perfil: "",
      iduser: "",
      postsToView: [],
      offset: 0,
      limit: 10,
    }
  },

  methods: {
    ZoomImage(imageURL){
        Swal.fire({
          imageUrl: imageURL,
        })
    },

    Tempo(value){
        return utils.TempoDePost(value);
    },

    CarregarInformacoes(){
      const dataToSend = {
        "opcode": 7,
        "email": this.emailCache 
      }
      axios.post(this.$backendDoServidor, dataToSend)
        .then(response => {
          console.log(response.data)
          this.nome = response.data[0][8];
          this.cep = response.data[0]["cep"];
          this.logradouro = response.data[0]["logradouro"];
          this.bairro = response.data[0]["bairro"];
          this.municipio = response.data[0]["municipio"];
          this.uf = response.data[0]["uf"];
          this.foto_perfil = response.data[0]["foto_perfil"];
          this.iduser = response.data[0]["id"]
          // console.log(this.iduser);
        })
        .catch(err => {
          console.log("Erro de requisição: ", err);
        })

        console.log("Informações carregadas!");
    
    },

    Sair(){

        swal({
          title: "Tem certza que deseja encerrar a sessão?",
          text: "Você deverá logar novamente para entrar",
          icon: "warning",
          buttons: ["Não, cancelar!", "Encerrar sessão!"],
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            console.log("Encerrando seção...");
            console.log("Cache limpo");
            localStorage.clear();
            this.$router.push("/");
          } 
          
          else {
            console.log("Operação cancelada");
            swal("Operação cancelada");
          }
        });
        
    },

    Editar(){
      console.log("Editando informações");
      this.$router.push("/editarperfil");
    },

    MonitorarScroll() {
        // Verifica se o usuário chegou ao final da página
        if ((window.innerHeight + window.scrollY) >= (document.documentElement.scrollHeight * 1) ) {
          // Se o usuário chegou ao bottom da página, faça alguma coisa
          this.CarregarMeusPosts(); // Por exemplo, carregar mais posts
        }
    },

    CarregarMeusPosts() {
        console.log("Carregando posts!");

        let dataToSend = {
          "opcode": 18,
          "email": this.emailCache,
          "limit": this.limit,
          "offset": this.offset
        }

        axios.post(this.$backendDoServidor, dataToSend)
          .then(response =>{
            // console.log(response.data);
            for (let i = 0; i < response.data.length; i++){
              // console.log(response.data[i]);

              this.postsToView.push(response.data[i]);
              // if (!this.postsToView.some(post => post[0] === response.data[i][0])) {
              // }
            }
            // if (this.postsToView.length >= this.postsLimit){
            //   this.postsToView.splice(0, this.postsLimit);
            // }
          })
          .catch(err =>{
            console.log("Erro: ", err);
          })
          this.offset += this.limit;
          // console.log("offset:", this.offset);
          // console.log("Posts:", this.postsToView.length);
        },

        EditarSituacao(value){
          let dataToSend = {
            "opcode": 21,
            "idpost": value
          }

          axios.post(this.$backendDoServidor, dataToSend)
            .then(response =>{
              console.log("Alterando situação...")
              location.reload();
            })
            .catch(err =>{
              console.error("Erro ao alterar situação: ", err)
            })

        },

        ExcluirPost(value){

          swal({
            title: "Tem certza que deseja excluir a postagem?",
            text: "Uma vez deletada, não poderá ser recuperada!",
            icon: "warning",
            buttons: ["Não, cancelar!", "Excluir postagem!"],
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
              let dataToSend = {
                "opcode": 22,
                "idpost": value
              }
        
              axios.post(this.$backendDoServidor, dataToSend)
                .then(response =>{
                  console.log("Deletando post...")
                  location.reload();
                })
                .catch(err =>{
                  console.error("Erro ao deletar post: ", err)
                })
            } 
            
            else {
              console.log("Operação cancelada");
              swal("Operação cancelada");
            }
          });
        }
  },

  mounted: function(){
    utils.IsLogged();
    window.addEventListener('scroll', this.MonitorarScroll);
    this.CarregarInformacoes();
    this.CarregarMeusPosts();
    // setTimeout(this.CarregarMeusPosts(), 1000);   
  },

  // watch: {
  //   iduser: function() {
  //       this.CarregarMeusPosts();
  //   }
  // }
};
</script>

<style scoped>
*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.fade-in {
  opacity: 0; /* Inicialmente, oculta o conteúdo */
  animation: fadeInAnimation 0.5s ease-in forwards; /* Animação de fade-in */
}
@keyframes fadeInAnimation {
  from {
    opacity: 0; /* Começa completamente transparente */
  }
  to {
    opacity: 1; /* Torna-se totalmente visível */
  }
}

.badge{
  margin-left: 1px;
  margin-right: 1px;
}
#informacoesDoUsuario{
  font-size: 20px;
  text-align: left;
  overflow: hidden;
  margin-top: 10px
}

#actions{
  margin-left: 2.5rem;
}
#actions button{
  margin-right: 1rem;
}
#actions i{
  font-size: 16px;
}

#idAssunto{
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-align: center;
  resize: none; 
  border: none;
  background-color: var(--azul-1);
  /* background: rgb(24,62,159);
  background: linear-gradient(0deg, rgba(24,62,159,1) 0%, rgba(27,156,228,1) 100%);  */
  /* box-shadow: 0.5px 0.5px 5px; */
  border-radius: 10px;
}

#topNavBar{
  top: 0;
  z-index: 2;
  position: fixed;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-between; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  width: 100%;
  padding: 2.5px;
  /* background: rgb(8, 96, 211); */
  background-color: var(--azul-1);
  /* background: rgb(24,62,159);
  background: linear-gradient(0deg, rgba(24,62,159,1) 0%, rgba(27,156,228,1) 100%);  */
  overflow: hidden;
  border: none;
  box-shadow: 0px 0px 5px;
}
#appTitle{
  color: white;
  text-shadow: 1px 1px 10px black;
  margin-left: 1.2rem;
}
#editarArea{
  /* margin-top: 0rem; */
  color: white;
  margin-right: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  position: fixed;
  right: 0;
}

#fotoNome{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#nomeDeUsuario{
  margin-top: 0.5rem;
  font-size: 2rem;
}

#informacoesDeUsuario{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.postsContainer{
  padding-top: 3.5rem;
  padding-bottom: 2rem;
}

.bg-warning{
  font-size: 15px;
}
.bg-success{
  font-size: 15px;
}

#newNavBar{
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  display: flex; /* Torna os itens filhos flexíveis */
  justify-content: space-around; /* Distribui os itens uniformemente na linha */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  /* background: rgb(167,167,167); */
  background: rgb(225, 225, 225);
  /* background: linear-gradient(0deg, rgba(225,225,225,1) 0%, rgba(255,255,255,1) 100%);   */
  height: 2.5rem;
  border: none;
  box-shadow: 0px 0px 5px;
}
.bi{
  /* text-shadow: 0px 0px 100px black; */
  cursor: pointer;
  font-size: 1.5rem; /* Altere o valor conforme desejado */ 
}
.navicon {
  font-size: 1.5rem; /* Altere o valor conforme desejado */
  color: rgb(160, 160, 160);
  text-shadow: none;
  margin: 5px;
}
.bi-person-fill{
  /* color: rgb(175,161,14); */
  /* color: rgb(50, 50, 50); */
  color: var(--azul-1);
  border-bottom: 3px solid var(--azul-1);
}

/* #container{
  padding-top: 3rem;
  padding-bottom: 1rem;
  text-align: justify;
  margin-left: 10%;
  margin-right: 10%;
} */


#container{
  padding-top: 3rem;
  padding-bottom: 2rem;
  text-align: justify;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: rgb(225, 225, 225); */
}

#container2{
  margin-top: 1rem;
  margin-bottom: 3rem;
  text-align: justify;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input[type="file"] {
      color: transparent;
}

#foto_perfil{
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Torna a imagem redonda */
  object-fit: cover; /* Garante que a imagem cubra todo o espaço designado */
  display: block; /* Permite a centralização vertical */
  margin: auto; /* Centraliza horizontalmente */
}

#foto_perfil2{
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Torna a imagem redonda */
  object-fit: cover; /* Garante que a imagem cubra todo o espaço designado */
  display: block; /* Permite a centralização vertical */
}


.list-group{
  background: rgb(255,255,255);
  /* box-shadow: 0px 0px 5px black; */
  /* border: none; */
  border: 2px solid rgb(206, 206, 206);
  border-radius: 10px;
}
/* .list-group-item{
  background: rgb(227, 227, 227);
  border: none;
  border: 1px solid black;
} */

.li-img{
  border: none;
}
.slider{
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 20rem;
  max-height: 20rem;
  border: none;
}
.slider-img{
  height: 11rem !important;
  width: auto !important;
  background-size: contain !important;
  /* background-repeat: no-repeat; */
}
/* img{
  box-shadow: 2px 2px 2px black;
} */

#foto_perfil2{
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Torna a imagem redonda */
  object-fit: cover; /* Garante que a imagem cubra todo o espaço designado */
  display: block; /* Permite a centralização vertical */
}
.postsContainer{
  padding-top: 0rem;
  padding-bottom: 0rem;
}

#descricao{
  text-align: justify;
  resize: none; 
  color: black;
  border: none;
  border-radius: 10px;
  background-color: transparent;
}

/* 
.slider{
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 20rem;
  max-height: 20rem;
  box-shadow: 1px 1px 1px black;
  border: none;
}
.postsContainer{
  margin-top: 5rem;
  display: flex;
} 

#descricao{
  text-align: justify;
  resize: none; 
  color: black;
  border: none;
}*/

/* Media query para telas menores que 600px */
@media screen and (min-width: 700px) {
  #container {
      font-size: 30px;      
  }
  #foto_perfil2{
    height: 250px;
    width: 250px;
  }
  .slider{
    max-width: 40rem;
    min-width: 40rem;
    max-height: 40rem;
  }
}

#content{
  background-color: rgb(228, 228, 228);
  padding-bottom: 35vh;
}
</style>