import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import LoginRecover from './views/LoginRecover.vue';
import Home from './views/Home.vue';
import Perfil from './views/Perfil.vue';
import EditarPerfil from './views/EditarPerfil.vue';
import Sobre from './views/Sobre.vue';
import Cadastro from './views/Cadastro.vue';
import Init from './views/Init.vue';
import Postar from './views/Postar.vue';
import InitChat from './views/InitChat.vue';
import Chat from './views/Chat.vue';
import MeusChats from './views/MeusChats.vue';
import ChatInstanciado from './views/ChatInstanciado.vue';
import LoginNovo from './views/LoginNovo.vue';
import RegistroViewNovo from './views/RegistroViewNovo.vue';
import VerPerfilUsuario from "./views/VerPerfilUsuario.vue";
import MeusChatsUsers from "./views/MeusChatsUsers.vue";

Vue.use(Router);

// import { createRouter, createWebHashHistory } from 'vue-router'
// import { createRouter, createWebHistory } from 'vue-router'


export default new Router({
  // mode: "history",
  // history: createWebHashHistory(),
  // base: "/",
  // history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'init',
      component: Init
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/loginnovo',
      name: 'loginnovo',
      component: LoginNovo
    },
    {
      path: '/registroviewnovo',
      name: 'registroviewnovo',
      component: RegistroViewNovo
    },
    {
      path: '/loginrecover',
      name: 'loginrecover',
      component: LoginRecover
    },
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/postar',
      name: 'postar',
      component: Postar
    },
    {
      path: '/initchat',
      name: 'initchat',
      component: InitChat
    },
    {
      path: '/chat',
      name: 'chat',
      component: Chat
    },
    {
      path: '/chatinstanciado',
      name: 'chatinstanciado',
      component: ChatInstanciado
    },
    {
      path: '/meuschats',
      name: 'meuschats',
      component: MeusChats
    },
    {
      path: '/meuschatsusers',
      name: 'meuschatsusers',
      component: MeusChatsUsers
    },
    {
      path: '/perfil',
      name: 'perfil',
      component: Perfil
    },
    {
      path: '/verperfilusuario',
      name: 'verperfilusuario',
      component: VerPerfilUsuario
    },
    {
      path: '/editarperfil',
      name: 'editarperfil',
      component: EditarPerfil
    },
    {
      path: '/sobre',
      name: 'sobre',
      component: Sobre
    },
    {
      path: '/cadastro',
      name: 'cadastro',
      component: Cadastro
    },
  ]
});