<template>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2'
import utils from '../utils';
import router from '../router';

export default {
    //  Nome do componente
    name: "meuschatsusers",

    //  Variáveis
    data: function(){
        return{
            email: localStorage.getItem("session-email"),
            meusChatsUsers: [],
        }
    },

    //  Local onde terá todas as funções do componente
    methods:{
        CarregarChatsUsers(){
            console.log("Carregando chats users...");

            let dataToSend = {
                "opcode": 30,
                "email": this.email
            }

            axios.post(this.$backendDoServidor, dataToSend)
                .then(response=>{
                    console.log(response.data);
                })
                .catch(err=>{
                    console.error("Erro ao carregar chats user: "+err);
                })
        }
    },

    // Funções que serão executadas quando a aplicação iniciar
    mounted: function(){
        this.CarregarChatsUsers();
    },

    //  Funções que serão executadas quando determinadas variáveis mudarem
    computed:{
    }
}

</script>

<style scoped>
</style>