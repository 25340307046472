<template>
    <div>
      
      <!-- Criação do Home do aplicativo "App Solidário". -->
      <div class="row">
        
        <!-- 
          Todo o conteúdo ficará e será exibido
          em uma única coluna para se adequar ao 
          modelo de aplicação mobile.  
        -->
        <div class="col">
  
          <div class="col" id="fundo_de_login">
            <p>
              Finalizando Cadastro
            </p>
    
            <!-- Grupos de input para email e senha. -->
            <div class="col">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="nome completo" v-model="nomeCompleto" required>
                </div>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="cep" v-model="cep" required @blur="confirmarCadastro()">
                </div>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="logradouro" v-model="logradouro" required>
                </div>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="bairro" v-model="bairro" required>
                </div>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="município" v-model="municipio" required>
                </div>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="unidade federativa" v-model="uf" required>
                </div>
            </div>
            
            <!-- Botão de finalizar cadastro. -->
            <div class="col">
                <button type="button" class="btn btn-success" @click="finalizarCadastro()">Finalizar cadastro</button>
            </div>
  
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "cadastro",

  //  Armazenamento de variáveis e valores.
  data: function(){
    return{
      nomeCompleto: "",
      cep: "",
      logradouro: "",
      bairro: "",
      municipio: "",
      uf: ""
    }
  },

  /*
    Definição de funções que serão utilizadas
    de acordo com a necessidade.
  */
  methods: {
    confirmarCadastro(){
        let cep = this.cep;
        const url = `https://viacep.com.br/ws/${cep}/json/`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                //  console.log(data);
                //  Faça o que quiser com os dados do endereço aqui
                //  console.log(data.logradouro);
                this.logradouro = data.logradouro;
                this.bairro = data.bairro;
                this.municipio = data.localidade;
                this.uf = data.uf;
            })
            .catch(error => console.error('Erro ao buscar CEP:', error));
    },

    finalizarCadastro(){
        if (this.nomeCompleto && this.cep && this.logradouro && this.bairro && this.municipio && this.uf){
            //console.log("Olá, Mundo!");

            const dataToSend = {
                "opcode": 6,
                "email": this.$route.params.email,
                "nome": this.nomeCompleto,
                "cep": this.cep,
                "logradouro": this.logradouro,
                "bairro": this.bairro,
                "municipio": this.municipio,
                "uf": this.uf
            }
            let vself = this;
            axios.post(this.$backendDoServidor, dataToSend)
                .then(response =>{
                    console.log(response.data);
                    vself.$router.push("/home");
                })
                .catch(error =>{
                    console.log("Erro de requisição: ", error);
                })
        }
    },

  },

  //  Funções que executam assim que a aplicação
  //  for carregada e inicializada.
  mounted: function(){
    this.$utils.IsLogged();
    document.body.id = 'custom-body';

  },

  //  Manipulação de dados que serão alterados
  //  durante a execução e interação com o programa.
  computed:{
    
  }
};

</script>

<style scoped>
</style>